/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document:
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AuthCredentials
 */
export interface AuthCredentials {
  /**
   *
   * @type {string}
   * @memberof AuthCredentials
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof AuthCredentials
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof AuthCredentials
   */
  token?: string
}
/**
 *
 * @export
 * @interface CreateOrder
 */
export interface CreateOrder {
  /**
   *
   * @type {number}
   * @memberof CreateOrder
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  customer_name: string
  /**
   *
   * @type {OrderOrderImportSource}
   * @memberof CreateOrder
   */
  order_import_source?: OrderOrderImportSource
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  source_order_display_id?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrder
   */
  status?: CreateOrderStatusEnum
  /**
   *
   * @type {Array<CreateOrderOrderItemsInner>}
   * @memberof CreateOrder
   */
  order_items: Array<CreateOrderOrderItemsInner>
}

export const CreateOrderStatusEnum = {
  InitError: 'INIT_ERROR',
  SkuResolutionFailed: 'SKU_RESOLUTION_FAILED',
  UserReview: 'USER_REVIEW',
  Provisioning: 'PROVISIONING',
  ReadyForDispatch: 'READY_FOR_DISPATCH',
  Complete: 'COMPLETE',
  Cancelled: 'CANCELLED'
} as const

export type CreateOrderStatusEnum =
  (typeof CreateOrderStatusEnum)[keyof typeof CreateOrderStatusEnum]

/**
 *
 * @export
 * @interface CreateOrderItem
 */
export interface CreateOrderItem {
  /**
   *
   * @type {number}
   * @memberof CreateOrderItem
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  updated?: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderItem
   */
  order: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderItem
   */
  sku?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  custom_text?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderItem
   */
  customization_has_been_performed?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  manufacturing_notes?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderItem
   */
  is_expanded?: boolean
  /**
   *
   * @type {number}
   * @memberof CreateOrderItem
   */
  expanded_from?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderItem
   */
  requires_post_processing?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  post_processing_notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderItem
   */
  unresolved_sku?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateOrderItem
   */
  count: number
}
/**
 *
 * @export
 * @interface CreateOrderOrderItemsInner
 */
export interface CreateOrderOrderItemsInner {
  /**
   *
   * @type {number}
   * @memberof CreateOrderOrderItemsInner
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  updated?: string
  /**
   *
   * @type {number}
   * @memberof CreateOrderOrderItemsInner
   */
  order?: number
  /**
   *
   * @type {number}
   * @memberof CreateOrderOrderItemsInner
   */
  sku?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  custom_text?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderOrderItemsInner
   */
  customization_has_been_performed?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  manufacturing_notes?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderOrderItemsInner
   */
  is_expanded?: boolean
  /**
   *
   * @type {number}
   * @memberof CreateOrderOrderItemsInner
   */
  expanded_from?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateOrderOrderItemsInner
   */
  requires_post_processing?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  post_processing_notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateOrderOrderItemsInner
   */
  unresolved_sku?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateOrderOrderItemsInner
   */
  count: number
}
/**
 *
 * @export
 * @interface CreateSku
 */
export interface CreateSku {
  /**
   *
   * @type {number}
   * @memberof CreateSku
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  sku_type: CreateSkuSkuTypeEnum
  /**
   *
   * @type {number}
   * @memberof CreateSku
   */
  material?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateSku
   */
  design_type?: CreateSkuDesignTypeEnum
  /**
   *
   * @type {Array<SkuChildSkusInner>}
   * @memberof CreateSku
   */
  child_skus?: Array<SkuChildSkusInner>
}

export const CreateSkuSkuTypeEnum = {
  Resale: 'RESALE',
  Manufactured: 'MANUFACTURED',
  Bundle: 'BUNDLE'
} as const

export type CreateSkuSkuTypeEnum = (typeof CreateSkuSkuTypeEnum)[keyof typeof CreateSkuSkuTypeEnum]
export const CreateSkuDesignTypeEnum = {
  Static: 'STATIC',
  Variable: 'VARIABLE',
  Custom: 'CUSTOM'
} as const

export type CreateSkuDesignTypeEnum =
  (typeof CreateSkuDesignTypeEnum)[keyof typeof CreateSkuDesignTypeEnum]

/**
 *
 * @export
 * @interface GetProfile200Response
 */
export interface GetProfile200Response {
  /**
   *
   * @type {number}
   * @memberof GetProfile200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof GetProfile200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof GetProfile200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<Profile>}
   * @memberof GetProfile200Response
   */
  results: Array<Profile>
}
/**
 *
 * @export
 * @interface GetSentryConfig200Response
 */
export interface GetSentryConfig200Response {
  /**
   *
   * @type {number}
   * @memberof GetSentryConfig200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof GetSentryConfig200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof GetSentryConfig200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<SentryConfig>}
   * @memberof GetSentryConfig200Response
   */
  results: Array<SentryConfig>
}
/**
 *
 * @export
 * @interface ListManufacturingFaults200Response
 */
export interface ListManufacturingFaults200Response {
  /**
   *
   * @type {number}
   * @memberof ListManufacturingFaults200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListManufacturingFaults200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListManufacturingFaults200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<ManufacturingFault>}
   * @memberof ListManufacturingFaults200Response
   */
  results: Array<ManufacturingFault>
}
/**
 *
 * @export
 * @interface ListMaterials200Response
 */
export interface ListMaterials200Response {
  /**
   *
   * @type {number}
   * @memberof ListMaterials200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListMaterials200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListMaterials200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<Material>}
   * @memberof ListMaterials200Response
   */
  results: Array<Material>
}
/**
 *
 * @export
 * @interface ListOrderImportSources200Response
 */
export interface ListOrderImportSources200Response {
  /**
   *
   * @type {number}
   * @memberof ListOrderImportSources200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListOrderImportSources200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListOrderImportSources200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<OrderImportSource>}
   * @memberof ListOrderImportSources200Response
   */
  results: Array<OrderImportSource>
}
/**
 *
 * @export
 * @interface ListOrderItemStatusCounts200Response
 */
export interface ListOrderItemStatusCounts200Response {
  /**
   *
   * @type {number}
   * @memberof ListOrderItemStatusCounts200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListOrderItemStatusCounts200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListOrderItemStatusCounts200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<ManufacturingItem>}
   * @memberof ListOrderItemStatusCounts200Response
   */
  results: Array<ManufacturingItem>
}
/**
 *
 * @export
 * @interface ListOrderItems200Response
 */
export interface ListOrderItems200Response {
  /**
   *
   * @type {number}
   * @memberof ListOrderItems200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListOrderItems200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListOrderItems200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<OrderItem>}
   * @memberof ListOrderItems200Response
   */
  results: Array<OrderItem>
}
/**
 *
 * @export
 * @interface ListOrders200Response
 */
export interface ListOrders200Response {
  /**
   *
   * @type {number}
   * @memberof ListOrders200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListOrders200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListOrders200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<Order>}
   * @memberof ListOrders200Response
   */
  results: Array<Order>
}
/**
 *
 * @export
 * @interface ListSkus200Response
 */
export interface ListSkus200Response {
  /**
   *
   * @type {number}
   * @memberof ListSkus200Response
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof ListSkus200Response
   */
  next?: string | null
  /**
   *
   * @type {string}
   * @memberof ListSkus200Response
   */
  previous?: string | null
  /**
   *
   * @type {Array<Sku>}
   * @memberof ListSkus200Response
   */
  results: Array<Sku>
}
/**
 *
 * @export
 * @interface LogManufacturingFault
 */
export interface LogManufacturingFault {
  /**
   *
   * @type {number}
   * @memberof LogManufacturingFault
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof LogManufacturingFault
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof LogManufacturingFault
   */
  from_status: string
}
/**
 *
 * @export
 * @interface LogMessage
 */
export interface LogMessage {
  /**
   *
   * @type {string}
   * @memberof LogMessage
   */
  level: LogMessageLevelEnum
  /**
   *
   * @type {string}
   * @memberof LogMessage
   */
  message: string
}

export const LogMessageLevelEnum = {
  Debug: 'DEBUG',
  Info: 'INFO',
  Warning: 'WARNING',
  Error: 'ERROR',
  Critical: 'CRITICAL'
} as const

export type LogMessageLevelEnum = (typeof LogMessageLevelEnum)[keyof typeof LogMessageLevelEnum]

/**
 *
 * @export
 * @interface ManuallyExpandRequest
 */
export interface ManuallyExpandRequest {
  /**
   *
   * @type {Array<SkuChildSkusInner>}
   * @memberof ManuallyExpandRequest
   */
  child_skus: Array<SkuChildSkusInner>
}
/**
 *
 * @export
 * @interface ManufacturingFault
 */
export interface ManufacturingFault {
  /**
   *
   * @type {number}
   * @memberof ManufacturingFault
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ManufacturingFault
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingFault
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingFault
   */
  description?: string | null
  /**
   *
   * @type {number}
   * @memberof ManufacturingFault
   */
  order_item?: number
  /**
   *
   * @type {number}
   * @memberof ManufacturingFault
   */
  order?: number
}
/**
 *
 * @export
 * @interface ManufacturingItem
 */
export interface ManufacturingItem {
  /**
   *
   * @type {number}
   * @memberof ManufacturingItem
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  updated?: string
  /**
   *
   * @type {number}
   * @memberof ManufacturingItem
   */
  order?: number
  /**
   *
   * @type {number}
   * @memberof ManufacturingItem
   */
  sku?: number
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  custom_text?: string
  /**
   *
   * @type {boolean}
   * @memberof ManufacturingItem
   */
  customization_has_been_performed?: boolean
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  manufacturing_notes?: string
  /**
   *
   * @type {boolean}
   * @memberof ManufacturingItem
   */
  is_expanded?: boolean
  /**
   *
   * @type {number}
   * @memberof ManufacturingItem
   */
  expanded_from?: number
  /**
   *
   * @type {boolean}
   * @memberof ManufacturingItem
   */
  requires_post_processing?: boolean
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  post_processing_notes?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  unresolved_sku?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  has_fault?: string
  /**
   *
   * @type {string}
   * @memberof ManufacturingItem
   */
  status?: ManufacturingItemStatusEnum
  /**
   *
   * @type {number}
   * @memberof ManufacturingItem
   */
  count?: number
  /**
   *
   * @type {boolean}
   * @memberof ManufacturingItem
   */
  has_design_file?: boolean
}

export const ManufacturingItemStatusEnum = {
  ReviewBlocked: 'REVIEW_BLOCKED',
  SkuResolutionFailed: 'SKU_RESOLUTION_FAILED',
  UserReview: 'USER_REVIEW',
  StockCheck: 'STOCK_CHECK',
  AwaitingStock: 'AWAITING_STOCK',
  AwaitingManufacturing: 'AWAITING_MANUFACTURING',
  StagedForNesting: 'STAGED_FOR_NESTING',
  AwaitingPostProcessing: 'AWAITING_POST_PROCESSING',
  ReadyForDispatch: 'READY_FOR_DISPATCH',
  Complete: 'COMPLETE',
  Cancelled: 'CANCELLED'
} as const

export type ManufacturingItemStatusEnum =
  (typeof ManufacturingItemStatusEnum)[keyof typeof ManufacturingItemStatusEnum]

/**
 *
 * @export
 * @interface Material
 */
export interface Material {
  /**
   *
   * @type {number}
   * @memberof Material
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Material
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof Material
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof Material
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof Material
   */
  color?: string
}
/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {number}
   * @memberof Order
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Order
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof Order
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof Order
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof Order
   */
  customer_name: string
  /**
   *
   * @type {OrderOrderImportSource}
   * @memberof Order
   */
  order_import_source?: OrderOrderImportSource
  /**
   *
   * @type {string}
   * @memberof Order
   */
  source_order_display_id?: string | null
  /**
   *
   * @type {string}
   * @memberof Order
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof Order
   */
  status?: OrderStatusEnum
  /**
   *
   * @type {Array<OrderOrderItemsInner>}
   * @memberof Order
   */
  order_items?: Array<OrderOrderItemsInner>
}

export const OrderStatusEnum = {
  InitError: 'INIT_ERROR',
  SkuResolutionFailed: 'SKU_RESOLUTION_FAILED',
  UserReview: 'USER_REVIEW',
  Provisioning: 'PROVISIONING',
  ReadyForDispatch: 'READY_FOR_DISPATCH',
  Complete: 'COMPLETE',
  Cancelled: 'CANCELLED'
} as const

export type OrderStatusEnum = (typeof OrderStatusEnum)[keyof typeof OrderStatusEnum]

/**
 *
 * @export
 * @interface OrderImportSource
 */
export interface OrderImportSource {
  /**
   *
   * @type {number}
   * @memberof OrderImportSource
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderImportSource
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof OrderImportSource
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof OrderImportSource
   */
  name?: string
}
/**
 *
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  updated?: string
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  order?: number
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  sku?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  custom_text?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderItem
   */
  customization_has_been_performed?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  manufacturing_notes?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderItem
   */
  is_expanded?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  expanded_from?: number | null
  /**
   *
   * @type {boolean}
   * @memberof OrderItem
   */
  requires_post_processing?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  post_processing_notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  unresolved_sku?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  has_fault?: string
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  count?: string
  /**
   *
   * @type {Array<OrderOrderItemsInnerOrderItemStatusCountsInner>}
   * @memberof OrderItem
   */
  order_item_status_counts?: Array<OrderOrderItemsInnerOrderItemStatusCountsInner>
  /**
   *
   * @type {boolean}
   * @memberof OrderItem
   */
  has_design_file?: boolean
}
/**
 *
 * @export
 * @interface OrderItemCancelCount
 */
export interface OrderItemCancelCount {
  /**
   *
   * @type {number}
   * @memberof OrderItemCancelCount
   */
  count: number
  /**
   *
   * @type {string}
   * @memberof OrderItemCancelCount
   */
  status: OrderItemCancelCountStatusEnum
}

export const OrderItemCancelCountStatusEnum = {
  ReviewBlocked: 'REVIEW_BLOCKED',
  SkuResolutionFailed: 'SKU_RESOLUTION_FAILED',
  UserReview: 'USER_REVIEW',
  StockCheck: 'STOCK_CHECK',
  AwaitingStock: 'AWAITING_STOCK',
  AwaitingManufacturing: 'AWAITING_MANUFACTURING',
  StagedForNesting: 'STAGED_FOR_NESTING',
  AwaitingPostProcessing: 'AWAITING_POST_PROCESSING',
  ReadyForDispatch: 'READY_FOR_DISPATCH',
  Complete: 'COMPLETE',
  Cancelled: 'CANCELLED'
} as const

export type OrderItemCancelCountStatusEnum =
  (typeof OrderItemCancelCountStatusEnum)[keyof typeof OrderItemCancelCountStatusEnum]

/**
 *
 * @export
 * @interface OrderOrderImportSource
 */
export interface OrderOrderImportSource {
  /**
   *
   * @type {number}
   * @memberof OrderOrderImportSource
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderOrderImportSource
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof OrderOrderImportSource
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof OrderOrderImportSource
   */
  name?: string
}
/**
 *
 * @export
 * @interface OrderOrderItemsInner
 */
export interface OrderOrderItemsInner {
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemsInner
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  updated?: string
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemsInner
   */
  order?: number
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemsInner
   */
  sku?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  custom_text?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderOrderItemsInner
   */
  customization_has_been_performed?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  manufacturing_notes?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderOrderItemsInner
   */
  is_expanded?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemsInner
   */
  expanded_from?: number | null
  /**
   *
   * @type {boolean}
   * @memberof OrderOrderItemsInner
   */
  requires_post_processing?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  post_processing_notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  unresolved_sku?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  has_fault?: string
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInner
   */
  count?: string
  /**
   *
   * @type {Array<OrderOrderItemsInnerOrderItemStatusCountsInner>}
   * @memberof OrderOrderItemsInner
   */
  order_item_status_counts?: Array<OrderOrderItemsInnerOrderItemStatusCountsInner>
  /**
   *
   * @type {boolean}
   * @memberof OrderOrderItemsInner
   */
  has_design_file?: boolean
}
/**
 *
 * @export
 * @interface OrderOrderItemsInnerOrderItemStatusCountsInner
 */
export interface OrderOrderItemsInnerOrderItemStatusCountsInner {
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemsInnerOrderItemStatusCountsInner
   */
  status?: OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemsInnerOrderItemStatusCountsInner
   */
  count?: number
}

export const OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum = {
  ReviewBlocked: 'REVIEW_BLOCKED',
  SkuResolutionFailed: 'SKU_RESOLUTION_FAILED',
  UserReview: 'USER_REVIEW',
  StockCheck: 'STOCK_CHECK',
  AwaitingStock: 'AWAITING_STOCK',
  AwaitingManufacturing: 'AWAITING_MANUFACTURING',
  StagedForNesting: 'STAGED_FOR_NESTING',
  AwaitingPostProcessing: 'AWAITING_POST_PROCESSING',
  ReadyForDispatch: 'READY_FOR_DISPATCH',
  Complete: 'COMPLETE',
  Cancelled: 'CANCELLED'
} as const

export type OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum =
  (typeof OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum)[keyof typeof OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum]

/**
 *
 * @export
 * @interface PreStockedOrderItemList
 */
export interface PreStockedOrderItemList {
  /**
   *
   * @type {Array<PreStockedOrderItemListPrestockedOrderItemsInner>}
   * @memberof PreStockedOrderItemList
   */
  prestocked_order_items?: Array<PreStockedOrderItemListPrestockedOrderItemsInner>
}
/**
 *
 * @export
 * @interface PreStockedOrderItemListPrestockedOrderItemsInner
 */
export interface PreStockedOrderItemListPrestockedOrderItemsInner {
  /**
   *
   * @type {number}
   * @memberof PreStockedOrderItemListPrestockedOrderItemsInner
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof PreStockedOrderItemListPrestockedOrderItemsInner
   */
  count: number
}
/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
  /**
   *
   * @type {ProfileCurrentUser}
   * @memberof Profile
   */
  current_user?: ProfileCurrentUser | null
}
/**
 *
 * @export
 * @interface ProfileCurrentUser
 */
export interface ProfileCurrentUser {
  /**
   *
   * @type {string}
   * @memberof ProfileCurrentUser
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof ProfileCurrentUser
   */
  firstname: string
  /**
   *
   * @type {string}
   * @memberof ProfileCurrentUser
   */
  lastname: string
}
/**
 *
 * @export
 * @interface SentryConfig
 */
export interface SentryConfig {
  /**
   *
   * @type {string}
   * @memberof SentryConfig
   */
  frontend_sentry_dsn: string
  /**
   *
   * @type {string}
   * @memberof SentryConfig
   */
  environment_type: string
}
/**
 *
 * @export
 * @interface Sku
 */
export interface Sku {
  /**
   *
   * @type {number}
   * @memberof Sku
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  sku_type?: SkuSkuTypeEnum
  /**
   *
   * @type {SkuMaterial}
   * @memberof Sku
   */
  material: SkuMaterial
  /**
   *
   * @type {string}
   * @memberof Sku
   */
  design_type?: SkuDesignTypeEnum
  /**
   *
   * @type {Array<SkuChildSkusInner>}
   * @memberof Sku
   */
  child_skus: Array<SkuChildSkusInner>
}

export const SkuSkuTypeEnum = {
  Resale: 'RESALE',
  Manufactured: 'MANUFACTURED',
  Bundle: 'BUNDLE'
} as const

export type SkuSkuTypeEnum = (typeof SkuSkuTypeEnum)[keyof typeof SkuSkuTypeEnum]
export const SkuDesignTypeEnum = {
  Static: 'STATIC',
  Variable: 'VARIABLE',
  Custom: 'CUSTOM'
} as const

export type SkuDesignTypeEnum = (typeof SkuDesignTypeEnum)[keyof typeof SkuDesignTypeEnum]

/**
 *
 * @export
 * @interface SkuChildSkusInner
 */
export interface SkuChildSkusInner {
  /**
   *
   * @type {number}
   * @memberof SkuChildSkusInner
   */
  child_sku: number
  /**
   *
   * @type {number}
   * @memberof SkuChildSkusInner
   */
  count?: number
}
/**
 *
 * @export
 * @interface SkuMaterial
 */
export interface SkuMaterial {
  /**
   *
   * @type {number}
   * @memberof SkuMaterial
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SkuMaterial
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof SkuMaterial
   */
  updated?: string
  /**
   *
   * @type {string}
   * @memberof SkuMaterial
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof SkuMaterial
   */
  color?: string
}
/**
 *
 * @export
 * @interface StatusUpdate
 */
export interface StatusUpdate {
  /**
   *
   * @type {number}
   * @memberof StatusUpdate
   */
  count: number
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItemCancelCount} [orderItemCancelCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelCountOrderItem: async (
      id: string,
      orderItemCancelCount?: OrderItemCancelCount,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancelCountOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/cancel_count/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItemCancelCount,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder: async (
      id: string,
      body?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancelOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/cancel/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderItem: async (
      id: string,
      orderItem?: OrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancelOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/cancel/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeOrder: async (
      id: string,
      order?: Order,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('completeOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/complete/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        order,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {CreateOrder} [createOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrder: async (
      createOrder?: CreateOrder,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/orders/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrder,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {CreateOrderItem} [createOrderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderItem: async (
      createOrderItem?: CreateOrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/order_items/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {CreateSku} [createSku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSku: async (
      createSku?: CreateSku,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/skus/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSku,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDesignFileZipOrderItem: async (
      body?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/order_items/generate_design_file_zip/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDesignFileOrderItem: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getDesignFileOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/get_design_file/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/profile/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * A view to provide the frontend with configuration data.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSentryConfig: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/sentry_config/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManufacturingFaults: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItem?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/manufacturing_faults/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItem !== undefined) {
        localVarQueryParameter['order_item'] = orderItem
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listMaterials: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/materials/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderImportSources: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/order_import_sources/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [orderItemId] order_item__id
     * @param {string} [orderItemIdIn] order_item__id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {string} [orderItemSkuMaterialLabel] order_item__sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderItemStatusCounts: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      orderItemId?: string,
      orderItemIdIn?: string,
      status?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      orderItemSkuMaterialLabel?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/manufacturable_items/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (orderItemId !== undefined) {
        localVarQueryParameter['order_item__id'] = orderItemId
      }

      if (orderItemIdIn !== undefined) {
        localVarQueryParameter['order_item__id__in'] = orderItemIdIn
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (statusIn !== undefined) {
        localVarQueryParameter['status__in'] = statusIn
      }

      if (orderItemSkuMaterialLabel !== undefined) {
        localVarQueryParameter['order_item__sku__material__label'] = orderItemSkuMaterialLabel
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderItems: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/order_items/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItemStatusCountsStatus !== undefined) {
        localVarQueryParameter['order_item_status_counts__status'] = orderItemStatusCountsStatus
      }

      if (orderItemStatusCountsStatusIn !== undefined) {
        localVarQueryParameter['order_item_status_counts__status__in'] =
          orderItemStatusCountsStatusIn
      }

      if (skuMaterialLabel !== undefined) {
        localVarQueryParameter['sku__material__label'] = skuMaterialLabel
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrders: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/orders/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (statusIn !== undefined) {
        localVarQueryParameter['status__in'] = statusIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSkus: async (
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/skus/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {LogManufacturingFault} [logManufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logManufacturingFaultOrderItem: async (
      id: string,
      logManufacturingFault?: LogManufacturingFault,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('logManufacturingFaultOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/log_manufacturing_fault/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        logManufacturingFault,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * A view for the frontend to log messages to the backend.
     * @param {LogMessage} [logMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logMessage: async (
      logMessage?: LogMessage,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/log_message/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        logMessage,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      username: string,
      password: string,
      token?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('login', 'username', username)
      // verify required parameter 'password' is not null or undefined
      assertParamExists('login', 'password', password)
      const localVarPath = `/api/login/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      if (username !== undefined) {
        localVarFormParams.append('username', username as any)
      }

      if (password !== undefined) {
        localVarFormParams.append('password', password as any)
      }

      if (token !== undefined) {
        localVarFormParams.append('token', token as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {ManuallyExpandRequest} [manuallyExpandRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manuallyExpandOrderItem: async (
      id: string,
      manuallyExpandRequest?: ManuallyExpandRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('manuallyExpandOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/manually_expand/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        manuallyExpandRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAwaitingStockOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markAwaitingStockOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_awaiting_stock/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markCustomizationPerformedOrderItem: async (
      id: string,
      orderItem?: OrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markCustomizationPerformedOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_customization_performed/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markCutSuccessfulOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markCutSuccessfulOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_cut_successful/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markInStockOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markInStockOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_in_stock/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markPostProcessingSuccessfulOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markPostProcessingSuccessfulOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_post_processing_successful/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markStagedForNestingOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markStagedForNestingOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_staged_for_nesting/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markStockReceivedOrderItem: async (
      id: string,
      statusUpdate?: StatusUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markStockReceivedOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/mark_stock_received/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        statusUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateManufacturingFault: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateManufacturingFault', 'id', id)
      const localVarPath = `/api/manufacturing_faults/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItem !== undefined) {
        localVarQueryParameter['order_item'] = orderItem
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        manufacturingFault,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateOrder: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (statusIn !== undefined) {
        localVarQueryParameter['status__in'] = statusIn
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        order,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateOrderItem: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItemStatusCountsStatus !== undefined) {
        localVarQueryParameter['order_item_status_counts__status'] = orderItemStatusCountsStatus
      }

      if (orderItemStatusCountsStatusIn !== undefined) {
        localVarQueryParameter['order_item_status_counts__status__in'] =
          orderItemStatusCountsStatusIn
      }

      if (skuMaterialLabel !== undefined) {
        localVarQueryParameter['sku__material__label'] = skuMaterialLabel
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSku: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('partialUpdateSku', 'id', id)
      const localVarPath = `/api/skus/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sku,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveManufacturingFault: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveManufacturingFault', 'id', id)
      const localVarPath = `/api/manufacturing_faults/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItem !== undefined) {
        localVarQueryParameter['order_item'] = orderItem
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this material.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveMaterial: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveMaterial', 'id', id)
      const localVarPath = `/api/materials/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrder: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (statusIn !== undefined) {
        localVarQueryParameter['status__in'] = statusIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order import source.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrderImportSource: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveOrderImportSource', 'id', id)
      const localVarPath = `/api/order_import_sources/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrderItem: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItemStatusCountsStatus !== undefined) {
        localVarQueryParameter['order_item_status_counts__status'] = orderItemStatusCountsStatus
      }

      if (orderItemStatusCountsStatusIn !== undefined) {
        localVarQueryParameter['order_item_status_counts__status__in'] =
          orderItemStatusCountsStatusIn
      }

      if (skuMaterialLabel !== undefined) {
        localVarQueryParameter['sku__material__label'] = skuMaterialLabel
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveSku: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrieveSku', 'id', id)
      const localVarPath = `/api/skus/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrySkuResolutionOrderItem: async (
      id: string,
      orderItem?: OrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('retrySkuResolutionOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/retry_sku_resolution/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Runs the order import from Etsy, Shopify, etc.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runImportOrdersJob: async (
      body?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/run_import_orders_job/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {PreStockedOrderItemList} [preStockedOrderItemList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendToProvisioningOrder: async (
      id: string,
      preStockedOrderItemList?: PreStockedOrderItemList,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('sendToProvisioningOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/send_to_provisioning/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        preStockedOrderItemList,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturingFault: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateManufacturingFault', 'id', id)
      const localVarPath = `/api/manufacturing_faults/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItem !== undefined) {
        localVarQueryParameter['order_item'] = orderItem
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        manufacturingFault,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrder: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateOrder', 'id', id)
      const localVarPath = `/api/orders/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (statusIn !== undefined) {
        localVarQueryParameter['status__in'] = statusIn
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        order,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderItem: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateOrderItem', 'id', id)
      const localVarPath = `/api/order_items/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      if (orderItemStatusCountsStatus !== undefined) {
        localVarQueryParameter['order_item_status_counts__status'] = orderItemStatusCountsStatus
      }

      if (orderItemStatusCountsStatusIn !== undefined) {
        localVarQueryParameter['order_item_status_counts__status__in'] =
          orderItemStatusCountsStatusIn
      }

      if (skuMaterialLabel !== undefined) {
        localVarQueryParameter['sku__material__label'] = skuMaterialLabel
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSku: async (
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSku', 'id', id)
      const localVarPath = `/api/skus/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (search !== undefined) {
        localVarQueryParameter['search'] = search
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering
      }

      if (id2 !== undefined) {
        localVarQueryParameter['id'] = id2
      }

      if (idIn !== undefined) {
        localVarQueryParameter['id__in'] = idIn
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sku,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItemCancelCount} [orderItemCancelCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelCountOrderItem(
      id: string,
      orderItemCancelCount?: OrderItemCancelCount,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemCancelCount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelCountOrderItem(
        id,
        orderItemCancelCount,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrder(
      id: string,
      body?: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(id, body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrderItem(
      id: string,
      orderItem?: OrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderItem(
        id,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeOrder(
      id: string,
      order?: Order,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeOrder(id, order, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateOrder} [createOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrder(
      createOrder?: CreateOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrder>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(createOrder, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateOrderItem} [createOrderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrderItem(
      createOrderItem?: CreateOrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderItem(
        createOrderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateSku} [createSku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSku(
      createSku?: CreateSku,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSku>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSku(createSku, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateDesignFileZipOrderItem(
      body?: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateDesignFileZipOrderItem(
        body,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDesignFileOrderItem(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDesignFileOrderItem(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfile(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(
        limit,
        offset,
        search,
        ordering,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * A view to provide the frontend with configuration data.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSentryConfig(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSentryConfig200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSentryConfig(
        limit,
        offset,
        search,
        ordering,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listManufacturingFaults(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItem?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListManufacturingFaults200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listManufacturingFaults(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listMaterials(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMaterials200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listMaterials(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listOrderImportSources(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrderImportSources200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listOrderImportSources(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [orderItemId] order_item__id
     * @param {string} [orderItemIdIn] order_item__id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {string} [orderItemSkuMaterialLabel] order_item__sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listOrderItemStatusCounts(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      orderItemId?: string,
      orderItemIdIn?: string,
      status?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      orderItemSkuMaterialLabel?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ListOrderItemStatusCounts200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listOrderItemStatusCounts(
        limit,
        offset,
        search,
        ordering,
        orderItemId,
        orderItemIdIn,
        status,
        statusIn,
        orderItemSkuMaterialLabel,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listOrderItems(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrderItems200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listOrderItems(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listOrders(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrders200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listOrders(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        status,
        statusIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSkus(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSkus200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listSkus(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {LogManufacturingFault} [logManufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logManufacturingFaultOrderItem(
      id: string,
      logManufacturingFault?: LogManufacturingFault,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogManufacturingFault>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logManufacturingFaultOrderItem(
        id,
        logManufacturingFault,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * A view for the frontend to log messages to the backend.
     * @param {LogMessage} [logMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logMessage(
      logMessage?: LogMessage,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogMessage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logMessage(logMessage, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      username: string,
      password: string,
      token?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthCredentials>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        username,
        password,
        token,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {ManuallyExpandRequest} [manuallyExpandRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async manuallyExpandOrderItem(
      id: string,
      manuallyExpandRequest?: ManuallyExpandRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManuallyExpandRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.manuallyExpandOrderItem(
        id,
        manuallyExpandRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAwaitingStockOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAwaitingStockOrderItem(
        id,
        statusUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markCustomizationPerformedOrderItem(
      id: string,
      orderItem?: OrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markCustomizationPerformedOrderItem(
        id,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markCutSuccessfulOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markCutSuccessfulOrderItem(
        id,
        statusUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markInStockOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markInStockOrderItem(
        id,
        statusUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markPostProcessingSuccessfulOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.markPostProcessingSuccessfulOrderItem(
          id,
          statusUpdate,
          options
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markStagedForNestingOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markStagedForNestingOrderItem(
        id,
        statusUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markStockReceivedOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markStockReceivedOrderItem(
        id,
        statusUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturingFault>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateManufacturingFault(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItem,
        manufacturingFault,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOrder(
        id,
        search,
        ordering,
        id2,
        idIn,
        status,
        statusIn,
        order,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partialUpdateSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sku>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSku(
        id,
        search,
        ordering,
        id2,
        idIn,
        sku,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturingFault>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveManufacturingFault(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this material.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveMaterial(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMaterial(
        id,
        search,
        ordering,
        id2,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOrder(
        id,
        search,
        ordering,
        id2,
        idIn,
        status,
        statusIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order import source.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveOrderImportSource(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderImportSource>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOrderImportSource(
        id,
        search,
        ordering,
        id2,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrieveSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sku>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSku(
        id,
        search,
        ordering,
        id2,
        idIn,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrySkuResolutionOrderItem(
      id: string,
      orderItem?: OrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrySkuResolutionOrderItem(
        id,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Runs the order import from Etsy, Shopify, etc.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runImportOrdersJob(
      body?: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runImportOrdersJob(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {PreStockedOrderItemList} [preStockedOrderItemList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendToProvisioningOrder(
      id: string,
      preStockedOrderItemList?: PreStockedOrderItemList,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreStockedOrderItemList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendToProvisioningOrder(
        id,
        preStockedOrderItemList,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturingFault>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturingFault(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItem,
        manufacturingFault,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(
        id,
        search,
        ordering,
        id2,
        idIn,
        status,
        statusIn,
        order,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        orderItem,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sku>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSku(
        id,
        search,
        ordering,
        id2,
        idIn,
        sku,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApiApiFp(configuration)
  return {
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItemCancelCount} [orderItemCancelCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelCountOrderItem(
      id: string,
      orderItemCancelCount?: OrderItemCancelCount,
      options?: any
    ): AxiosPromise<OrderItemCancelCount> {
      return localVarFp
        .cancelCountOrderItem(id, orderItemCancelCount, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(id: string, body?: any, options?: any): AxiosPromise<any> {
      return localVarFp.cancelOrder(id, body, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderItem(id: string, orderItem?: OrderItem, options?: any): AxiosPromise<OrderItem> {
      return localVarFp
        .cancelOrderItem(id, orderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeOrder(id: string, order?: Order, options?: any): AxiosPromise<Order> {
      return localVarFp
        .completeOrder(id, order, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateOrder} [createOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrder(createOrder?: CreateOrder, options?: any): AxiosPromise<CreateOrder> {
      return localVarFp
        .createOrder(createOrder, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateOrderItem} [createOrderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderItem(
      createOrderItem?: CreateOrderItem,
      options?: any
    ): AxiosPromise<CreateOrderItem> {
      return localVarFp
        .createOrderItem(createOrderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateSku} [createSku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSku(createSku?: CreateSku, options?: any): AxiosPromise<CreateSku> {
      return localVarFp.createSku(createSku, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDesignFileZipOrderItem(body?: any, options?: any): AxiosPromise<any> {
      return localVarFp
        .generateDesignFileZipOrderItem(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDesignFileOrderItem(id: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .getDesignFileOrderItem(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options?: any
    ): AxiosPromise<GetProfile200Response> {
      return localVarFp
        .getProfile(limit, offset, search, ordering, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * A view to provide the frontend with configuration data.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSentryConfig(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      options?: any
    ): AxiosPromise<GetSentryConfig200Response> {
      return localVarFp
        .getSentryConfig(limit, offset, search, ordering, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManufacturingFaults(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItem?: string,
      options?: any
    ): AxiosPromise<ListManufacturingFaults200Response> {
      return localVarFp
        .listManufacturingFaults(limit, offset, search, ordering, id, idIn, orderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listMaterials(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<ListMaterials200Response> {
      return localVarFp
        .listMaterials(limit, offset, search, ordering, id, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderImportSources(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<ListOrderImportSources200Response> {
      return localVarFp
        .listOrderImportSources(limit, offset, search, ordering, id, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [orderItemId] order_item__id
     * @param {string} [orderItemIdIn] order_item__id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {string} [orderItemSkuMaterialLabel] order_item__sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderItemStatusCounts(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      orderItemId?: string,
      orderItemIdIn?: string,
      status?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      orderItemSkuMaterialLabel?: string,
      options?: any
    ): AxiosPromise<ListOrderItemStatusCounts200Response> {
      return localVarFp
        .listOrderItemStatusCounts(
          limit,
          offset,
          search,
          ordering,
          orderItemId,
          orderItemIdIn,
          status,
          statusIn,
          orderItemSkuMaterialLabel,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrderItems(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options?: any
    ): AxiosPromise<ListOrderItems200Response> {
      return localVarFp
        .listOrderItems(
          limit,
          offset,
          search,
          ordering,
          id,
          idIn,
          orderItemStatusCountsStatus,
          orderItemStatusCountsStatusIn,
          skuMaterialLabel,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOrders(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options?: any
    ): AxiosPromise<ListOrders200Response> {
      return localVarFp
        .listOrders(limit, offset, search, ordering, id, idIn, status, statusIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSkus(
      limit?: number,
      offset?: number,
      search?: string,
      ordering?: string,
      id?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<ListSkus200Response> {
      return localVarFp
        .listSkus(limit, offset, search, ordering, id, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {LogManufacturingFault} [logManufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logManufacturingFaultOrderItem(
      id: string,
      logManufacturingFault?: LogManufacturingFault,
      options?: any
    ): AxiosPromise<LogManufacturingFault> {
      return localVarFp
        .logManufacturingFaultOrderItem(id, logManufacturingFault, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * A view for the frontend to log messages to the backend.
     * @param {LogMessage} [logMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logMessage(logMessage?: LogMessage, options?: any): AxiosPromise<LogMessage> {
      return localVarFp.logMessage(logMessage, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(
      username: string,
      password: string,
      token?: string,
      options?: any
    ): AxiosPromise<AuthCredentials> {
      return localVarFp
        .login(username, password, token, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {ManuallyExpandRequest} [manuallyExpandRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manuallyExpandOrderItem(
      id: string,
      manuallyExpandRequest?: ManuallyExpandRequest,
      options?: any
    ): AxiosPromise<ManuallyExpandRequest> {
      return localVarFp
        .manuallyExpandOrderItem(id, manuallyExpandRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAwaitingStockOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markAwaitingStockOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markCustomizationPerformedOrderItem(
      id: string,
      orderItem?: OrderItem,
      options?: any
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .markCustomizationPerformedOrderItem(id, orderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markCutSuccessfulOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markCutSuccessfulOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markInStockOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markInStockOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markPostProcessingSuccessfulOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markPostProcessingSuccessfulOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markStagedForNestingOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markStagedForNestingOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {StatusUpdate} [statusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markStockReceivedOrderItem(
      id: string,
      statusUpdate?: StatusUpdate,
      options?: any
    ): AxiosPromise<StatusUpdate> {
      return localVarFp
        .markStockReceivedOrderItem(id, statusUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options?: any
    ): AxiosPromise<ManufacturingFault> {
      return localVarFp
        .partialUpdateManufacturingFault(
          id,
          search,
          ordering,
          id2,
          idIn,
          orderItem,
          manufacturingFault,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options?: any
    ): AxiosPromise<Order> {
      return localVarFp
        .partialUpdateOrder(id, search, ordering, id2, idIn, status, statusIn, order, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options?: any
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .partialUpdateOrderItem(
          id,
          search,
          ordering,
          id2,
          idIn,
          orderItemStatusCountsStatus,
          orderItemStatusCountsStatusIn,
          skuMaterialLabel,
          orderItem,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partialUpdateSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options?: any
    ): AxiosPromise<Sku> {
      return localVarFp
        .partialUpdateSku(id, search, ordering, id2, idIn, sku, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      options?: any
    ): AxiosPromise<ManufacturingFault> {
      return localVarFp
        .retrieveManufacturingFault(id, search, ordering, id2, idIn, orderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this material.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveMaterial(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<Material> {
      return localVarFp
        .retrieveMaterial(id, search, ordering, id2, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      options?: any
    ): AxiosPromise<Order> {
      return localVarFp
        .retrieveOrder(id, search, ordering, id2, idIn, status, statusIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order import source.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrderImportSource(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<OrderImportSource> {
      return localVarFp
        .retrieveOrderImportSource(id, search, ordering, id2, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      options?: any
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .retrieveOrderItem(
          id,
          search,
          ordering,
          id2,
          idIn,
          orderItemStatusCountsStatus,
          orderItemStatusCountsStatusIn,
          skuMaterialLabel,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrieveSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      options?: any
    ): AxiosPromise<Sku> {
      return localVarFp
        .retrieveSku(id, search, ordering, id2, idIn, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrySkuResolutionOrderItem(
      id: string,
      orderItem?: OrderItem,
      options?: any
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .retrySkuResolutionOrderItem(id, orderItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Runs the order import from Etsy, Shopify, etc.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runImportOrdersJob(body?: any, options?: any): AxiosPromise<any> {
      return localVarFp
        .runImportOrdersJob(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {PreStockedOrderItemList} [preStockedOrderItemList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendToProvisioningOrder(
      id: string,
      preStockedOrderItemList?: PreStockedOrderItemList,
      options?: any
    ): AxiosPromise<PreStockedOrderItemList> {
      return localVarFp
        .sendToProvisioningOrder(id, preStockedOrderItemList, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this manufacturing fault.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {string} [orderItem] order_item
     * @param {ManufacturingFault} [manufacturingFault]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturingFault(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItem?: string,
      manufacturingFault?: ManufacturingFault,
      options?: any
    ): AxiosPromise<ManufacturingFault> {
      return localVarFp
        .updateManufacturingFault(
          id,
          search,
          ordering,
          id2,
          idIn,
          orderItem,
          manufacturingFault,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
     * @param {string} [statusIn] status__in
     * @param {Order} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrder(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      status?:
        | 'INIT_ERROR'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'PROVISIONING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      statusIn?: string,
      order?: Order,
      options?: any
    ): AxiosPromise<Order> {
      return localVarFp
        .updateOrder(id, search, ordering, id2, idIn, status, statusIn, order, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this order item.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
     * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
     * @param {string} [skuMaterialLabel] sku__material__label
     * @param {OrderItem} [orderItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderItem(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      orderItemStatusCountsStatus?:
        | 'REVIEW_BLOCKED'
        | 'SKU_RESOLUTION_FAILED'
        | 'USER_REVIEW'
        | 'STOCK_CHECK'
        | 'AWAITING_STOCK'
        | 'AWAITING_MANUFACTURING'
        | 'STAGED_FOR_NESTING'
        | 'AWAITING_POST_PROCESSING'
        | 'READY_FOR_DISPATCH'
        | 'COMPLETE'
        | 'CANCELLED',
      orderItemStatusCountsStatusIn?: string,
      skuMaterialLabel?: string,
      orderItem?: OrderItem,
      options?: any
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .updateOrderItem(
          id,
          search,
          ordering,
          id2,
          idIn,
          orderItemStatusCountsStatus,
          orderItemStatusCountsStatusIn,
          skuMaterialLabel,
          orderItem,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id A unique integer value identifying this sku.
     * @param {string} [search] A search term.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [id2] id
     * @param {string} [idIn] id__in
     * @param {Sku} [sku]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSku(
      id: string,
      search?: string,
      ordering?: string,
      id2?: string,
      idIn?: string,
      sku?: Sku,
      options?: any
    ): AxiosPromise<Sku> {
      return localVarFp
        .updateSku(id, search, ordering, id2, idIn, sku, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {OrderItemCancelCount} [orderItemCancelCount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public cancelCountOrderItem(
    id: string,
    orderItemCancelCount?: OrderItemCancelCount,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .cancelCountOrderItem(id, orderItemCancelCount, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {any} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public cancelOrder(id: string, body?: any, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .cancelOrder(id, body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {OrderItem} [orderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public cancelOrderItem(id: string, orderItem?: OrderItem, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .cancelOrderItem(id, orderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {Order} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public completeOrder(id: string, order?: Order, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .completeOrder(id, order, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateOrder} [createOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public createOrder(createOrder?: CreateOrder, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .createOrder(createOrder, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateOrderItem} [createOrderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public createOrderItem(createOrderItem?: CreateOrderItem, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .createOrderItem(createOrderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateSku} [createSku]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public createSku(createSku?: CreateSku, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .createSku(createSku, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {any} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public generateDesignFileZipOrderItem(body?: any, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .generateDesignFileZipOrderItem(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getDesignFileOrderItem(id: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getDesignFileOrderItem(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getProfile(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getProfile(limit, offset, search, ordering, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * A view to provide the frontend with configuration data.
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getSentryConfig(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getSentryConfig(limit, offset, search, ordering, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {string} [orderItem] order_item
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listManufacturingFaults(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    orderItem?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listManufacturingFaults(limit, offset, search, ordering, id, idIn, orderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listMaterials(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listMaterials(limit, offset, search, ordering, id, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listOrderImportSources(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listOrderImportSources(limit, offset, search, ordering, id, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [orderItemId] order_item__id
   * @param {string} [orderItemIdIn] order_item__id__in
   * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
   * @param {string} [statusIn] status__in
   * @param {string} [orderItemSkuMaterialLabel] order_item__sku__material__label
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listOrderItemStatusCounts(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    orderItemId?: string,
    orderItemIdIn?: string,
    status?:
      | 'REVIEW_BLOCKED'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'STOCK_CHECK'
      | 'AWAITING_STOCK'
      | 'AWAITING_MANUFACTURING'
      | 'STAGED_FOR_NESTING'
      | 'AWAITING_POST_PROCESSING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    statusIn?: string,
    orderItemSkuMaterialLabel?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listOrderItemStatusCounts(
        limit,
        offset,
        search,
        ordering,
        orderItemId,
        orderItemIdIn,
        status,
        statusIn,
        orderItemSkuMaterialLabel,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
   * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
   * @param {string} [skuMaterialLabel] sku__material__label
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listOrderItems(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    orderItemStatusCountsStatus?:
      | 'REVIEW_BLOCKED'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'STOCK_CHECK'
      | 'AWAITING_STOCK'
      | 'AWAITING_MANUFACTURING'
      | 'STAGED_FOR_NESTING'
      | 'AWAITING_POST_PROCESSING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    orderItemStatusCountsStatusIn?: string,
    skuMaterialLabel?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listOrderItems(
        limit,
        offset,
        search,
        ordering,
        id,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
   * @param {string} [statusIn] status__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listOrders(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    status?:
      | 'INIT_ERROR'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'PROVISIONING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    statusIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listOrders(limit, offset, search, ordering, id, idIn, status, statusIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [limit] Number of results to return per page.
   * @param {number} [offset] The initial index from which to return the results.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public listSkus(
    limit?: number,
    offset?: number,
    search?: string,
    ordering?: string,
    id?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .listSkus(limit, offset, search, ordering, id, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {LogManufacturingFault} [logManufacturingFault]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public logManufacturingFaultOrderItem(
    id: string,
    logManufacturingFault?: LogManufacturingFault,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .logManufacturingFaultOrderItem(id, logManufacturingFault, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * A view for the frontend to log messages to the backend.
   * @param {LogMessage} [logMessage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public logMessage(logMessage?: LogMessage, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .logMessage(logMessage, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} username
   * @param {string} password
   * @param {string} [token]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public login(username: string, password: string, token?: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .login(username, password, token, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {ManuallyExpandRequest} [manuallyExpandRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public manuallyExpandOrderItem(
    id: string,
    manuallyExpandRequest?: ManuallyExpandRequest,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .manuallyExpandOrderItem(id, manuallyExpandRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markAwaitingStockOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markAwaitingStockOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {OrderItem} [orderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markCustomizationPerformedOrderItem(
    id: string,
    orderItem?: OrderItem,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markCustomizationPerformedOrderItem(id, orderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markCutSuccessfulOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markCutSuccessfulOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markInStockOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markInStockOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markPostProcessingSuccessfulOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markPostProcessingSuccessfulOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markStagedForNestingOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markStagedForNestingOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {StatusUpdate} [statusUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public markStockReceivedOrderItem(
    id: string,
    statusUpdate?: StatusUpdate,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .markStockReceivedOrderItem(id, statusUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this manufacturing fault.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {string} [orderItem] order_item
   * @param {ManufacturingFault} [manufacturingFault]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public partialUpdateManufacturingFault(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItem?: string,
    manufacturingFault?: ManufacturingFault,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .partialUpdateManufacturingFault(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItem,
        manufacturingFault,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
   * @param {string} [statusIn] status__in
   * @param {Order} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public partialUpdateOrder(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    status?:
      | 'INIT_ERROR'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'PROVISIONING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    statusIn?: string,
    order?: Order,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .partialUpdateOrder(id, search, ordering, id2, idIn, status, statusIn, order, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
   * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
   * @param {string} [skuMaterialLabel] sku__material__label
   * @param {OrderItem} [orderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public partialUpdateOrderItem(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItemStatusCountsStatus?:
      | 'REVIEW_BLOCKED'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'STOCK_CHECK'
      | 'AWAITING_STOCK'
      | 'AWAITING_MANUFACTURING'
      | 'STAGED_FOR_NESTING'
      | 'AWAITING_POST_PROCESSING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    orderItemStatusCountsStatusIn?: string,
    skuMaterialLabel?: string,
    orderItem?: OrderItem,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .partialUpdateOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        orderItem,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this sku.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {Sku} [sku]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public partialUpdateSku(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    sku?: Sku,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .partialUpdateSku(id, search, ordering, id2, idIn, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this manufacturing fault.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {string} [orderItem] order_item
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveManufacturingFault(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItem?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveManufacturingFault(id, search, ordering, id2, idIn, orderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this material.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveMaterial(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveMaterial(id, search, ordering, id2, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
   * @param {string} [statusIn] status__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveOrder(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    status?:
      | 'INIT_ERROR'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'PROVISIONING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    statusIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveOrder(id, search, ordering, id2, idIn, status, statusIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order import source.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveOrderImportSource(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveOrderImportSource(id, search, ordering, id2, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
   * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
   * @param {string} [skuMaterialLabel] sku__material__label
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveOrderItem(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItemStatusCountsStatus?:
      | 'REVIEW_BLOCKED'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'STOCK_CHECK'
      | 'AWAITING_STOCK'
      | 'AWAITING_MANUFACTURING'
      | 'STAGED_FOR_NESTING'
      | 'AWAITING_POST_PROCESSING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    orderItemStatusCountsStatusIn?: string,
    skuMaterialLabel?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this sku.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrieveSku(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrieveSku(id, search, ordering, id2, idIn, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {OrderItem} [orderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public retrySkuResolutionOrderItem(
    id: string,
    orderItem?: OrderItem,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .retrySkuResolutionOrderItem(id, orderItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Runs the order import from Etsy, Shopify, etc.
   * @param {any} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public runImportOrdersJob(body?: any, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .runImportOrdersJob(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {PreStockedOrderItemList} [preStockedOrderItemList]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public sendToProvisioningOrder(
    id: string,
    preStockedOrderItemList?: PreStockedOrderItemList,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .sendToProvisioningOrder(id, preStockedOrderItemList, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this manufacturing fault.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {string} [orderItem] order_item
   * @param {ManufacturingFault} [manufacturingFault]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public updateManufacturingFault(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItem?: string,
    manufacturingFault?: ManufacturingFault,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .updateManufacturingFault(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItem,
        manufacturingFault,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'INIT_ERROR' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'PROVISIONING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [status] status
   * @param {string} [statusIn] status__in
   * @param {Order} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public updateOrder(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    status?:
      | 'INIT_ERROR'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'PROVISIONING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    statusIn?: string,
    order?: Order,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .updateOrder(id, search, ordering, id2, idIn, status, statusIn, order, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this order item.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {'REVIEW_BLOCKED' | 'SKU_RESOLUTION_FAILED' | 'USER_REVIEW' | 'STOCK_CHECK' | 'AWAITING_STOCK' | 'AWAITING_MANUFACTURING' | 'STAGED_FOR_NESTING' | 'AWAITING_POST_PROCESSING' | 'READY_FOR_DISPATCH' | 'COMPLETE' | 'CANCELLED'} [orderItemStatusCountsStatus] order_item_status_counts__status
   * @param {string} [orderItemStatusCountsStatusIn] order_item_status_counts__status__in
   * @param {string} [skuMaterialLabel] sku__material__label
   * @param {OrderItem} [orderItem]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public updateOrderItem(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    orderItemStatusCountsStatus?:
      | 'REVIEW_BLOCKED'
      | 'SKU_RESOLUTION_FAILED'
      | 'USER_REVIEW'
      | 'STOCK_CHECK'
      | 'AWAITING_STOCK'
      | 'AWAITING_MANUFACTURING'
      | 'STAGED_FOR_NESTING'
      | 'AWAITING_POST_PROCESSING'
      | 'READY_FOR_DISPATCH'
      | 'COMPLETE'
      | 'CANCELLED',
    orderItemStatusCountsStatusIn?: string,
    skuMaterialLabel?: string,
    orderItem?: OrderItem,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .updateOrderItem(
        id,
        search,
        ordering,
        id2,
        idIn,
        orderItemStatusCountsStatus,
        orderItemStatusCountsStatusIn,
        skuMaterialLabel,
        orderItem,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id A unique integer value identifying this sku.
   * @param {string} [search] A search term.
   * @param {string} [ordering] Which field to use when ordering the results.
   * @param {string} [id2] id
   * @param {string} [idIn] id__in
   * @param {Sku} [sku]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public updateSku(
    id: string,
    search?: string,
    ordering?: string,
    id2?: string,
    idIn?: string,
    sku?: Sku,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .updateSku(id, search, ordering, id2, idIn, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
