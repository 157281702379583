<template>
  <tr
    ref="rowRef"
    :class="[
      { 'h-12 ': props.show },
      {
        'odd:bg-slate-600/[0.05] hover:bg-slate-600/[0.15] bg-white ':
          !props.highlighted && !props.selectedRows[props.selectedIndex]
      },
      {
        'hover:bg-red-700/[0.2] odd:bg-rose-700/[0.13] bg-rose-700/[0.08] ':
          !!props.highlighted && !props.selectedRows[props.selectedIndex]
      },
      {
        'hover:bg-indigo-800/[0.2] odd:bg-indigo-600/[0.1] bg-indigo-600/[0.05] ':
          !!props.selectedRows[props.selectedIndex] && !props.highlighted
      },
      {
        'hover:bg-fuchsia-800/[0.25] odd:bg-fuchsia-700/[0.15] bg-fuchsia-700/[0.1] ':
          !!props.selectedRows[props.selectedIndex] && !!props.highlighted
      }
    ]"
  >
    <slot v-if="props.show && render"></slot>
    <td v-if="!render" colspan="100%"></td>
  </tr>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { throwError } from '@/services/ErrorHandler'

const rowRef = ref(null)
let observer = new IntersectionObserver(() => {}, {})
let observerMobile = new IntersectionObserver(() => {}, {})
const render = ref(false)
const props = defineProps({
  show: {
    type: Boolean,
    default: true
  },
  highlighted: {
    type: Boolean,
    default: false
  },
  selectedRows: {
    type: Array,
    default: () => []
  },
  selectedIndex: {
    type: Number,
    default: 0
  }
})

onMounted(() => observe())

onUnmounted(() => unobserve())

const unobserve = () => {
  observer.disconnect()
  observerMobile.disconnect()
}

const observe = (): void => {
  try {
    if (!('IntersectionObserver' in window && rowRef?.value)) return
    const row = rowRef?.value
    const rowParnt = getScrollParent(row)
    const rowParntParnt = getMobileScrollParent(row)

    if (!rowParnt) {
      render.value = true
      return
    }

    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) render.value = true
        if (render.value && row) observer.unobserve(row)
      },
      { root: rowParnt, rootMargin: '50px', threshold: 0 }
    )

    observerMobile = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) render.value = true
        if (render.value && row) observerMobile.unobserve(row)
      },
      { root: rowParntParnt, rootMargin: '50px', threshold: 0 }
    )

    if (row) observer.observe(row)
    if (row) observerMobile.observe(row)
  } catch (e) {
    throwError(e)
  }
}

const getMobileScrollParent = (el: HTMLElement | undefined): HTMLElement | undefined => {
  return getScrollParent(
    getParent(getScrollParent(getParent(getScrollParent(getParent(getScrollParent(el))))))
  )
}

const getParent = (el: HTMLElement | undefined | null): HTMLElement | undefined => {
  let elParent: HTMLElement | undefined | null = el
  if (!elParent || !elParent.parentElement) return undefined
  return elParent.parentElement as HTMLElement
}

const getScrollParent = (el: HTMLElement | undefined | null): HTMLElement | undefined => {
  let elParent: HTMLElement | undefined = el || undefined

  for (let i = 0; i < 50; i++) {
    if (!elParent || !elParent.scrollHeight || !elParent.clientHeight || !elParent.parentElement) {
      elParent = undefined
      break
    }
    if (elParent.scrollHeight > elParent.clientHeight) break
    elParent = elParent.parentElement as HTMLElement
  }

  return elParent
}
</script>
