<template>
  <dialog-content :dialog-title="dialogTitle" :buttons="[{ label: 'Ok', onClick: () => close() }]">
    <p class="px-2 sm:px-0 whitespace-pre-line text-sm text-gray-500 leading-6">
      {{ message && message.message ? message.message : '' }}
    </p>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'

export default defineComponent({
  name: 'NotificationDialogContent',
  components: { DialogContent },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    dialogProp: {
      type: Object as PropType<object | null>,
      required: true
    }
  },
  computed: {
    dialogTitle() {
      return this.message && this.message.title ? this.message.title : ''
    }
  },
  data() {
    return {
      message: this.dialogProp as { title: string; message: string }
    }
  },
  methods: {
    close() {
      this.$emit('update:dialogOpen', false)
    }
  }
})
</script>
