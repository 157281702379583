<template>
  <div
    :class="[
      $props.class || 'font-semibold w-full justify-center flex items-center',
      propObject.textClass || ''
    ]"
    v-tooltip="propObject.tooltip"
  >
    {{ propObject.before || '' }}
    <component
      v-if="propObject.iconComponent && typeof propObject.iconComponent === 'function'"
      :is="
        propObject.iconComponent && typeof propObject.iconComponent === 'function'
          ? propObject.iconComponent
          : {}
      "
      :class="propObject.class || 'w-6 h-6 inline-block text-neutral-500'"
    >
    </component>
    {{ propObject.after || '' }}
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import TableHeadCell from '@/components/Table/TableHeadCell.vue'
import { Cog8ToothIcon, BookOpenIcon } from '@heroicons/vue/24/outline'

export default defineComponent({
  name: 'TableIconComponent',
  components: { TableHeadCell, Cog8ToothIcon, BookOpenIcon },
  props: {
    propObject: {
      type: Object as PropType<{
        iconComponent?: object | undefined
        before?: string | undefined
        after?: string | undefined
        tooltip?: string | undefined
        class?: string | undefined
        textClass?: string | undefined
      }>,
      required: true
    },
    class: {
      default: '',
      type: String
    }
  }
})
</script>
