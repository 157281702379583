<template>
  <div
    class="grid sm:auto-cols-auto items-center sm:gap-x-4 sm:gap-y-0 sm:grid-cols-3"
    :class="[$attrs.class || '', slimStyling ? '' : 'mb-1 py-1 sm:pl-4']"
    v-tooltip="tooltip"
    :data-testid="$attrs['data-testid'] ?? 'input-component-base-test-id'"
    :id="$attrs.id?.toString() ?? undefined"
  >
    <div class="sm:col-span-1 sm:flex" v-if="showColumns">
      <label
        v-if="hasLabel"
        :for="name"
        class="sm:flex-1 sm:relative sm:block cursor-text text-sm text-center sm:text-left font-semibold text-gray-900 leading-6"
        :class="disabled && userInputVisible ? 'text-gray-400' : 'text-gray-900'"
        >{{ label }}<question-mark-tip-component :tip-text="tooltip"
      /></label>
    </div>
    <div
      class="sm:col-span-2 text-center mt-2 sm:mt-0 flex items-center justify-center w-full"
      :class="showColumns ? 'sm:col-span-2' : 'sm:col-span-full'"
    >
      <slot />
    </div>
  </div>
  <div class="bottom-divider" v-if="showDivider" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import QuestionMarkTipComponent from '@/components/QuestionMarkTipComponent.vue'
export default defineComponent({
  name: 'InputComponentBase',
  components: { QuestionMarkTipComponent },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    userInputVisible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    fullWidthOnBlankLabel: {
      type: Boolean,
      default: true
    },
    slimStyling: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasLabel(): boolean {
      return !!this.label && this.label.length > 0
    },
    showColumns(): boolean {
      return this.hasLabel || !this.fullWidthOnBlankLabel
    }
  }
})
</script>
