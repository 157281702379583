<template>
  <slot v-if="render"></slot>
  <tr
    v-if="!render"
    v-show="props.visibleRowSet === props.index"
    class="w-full text-center table-row"
    ref="row"
  >
    <td colspan="100%">
      <p class="loading text-center text-sm text-gray-500 pb-3 sm:pb-5 pt-4 sm:pt-6">
        {{ 'Processing' }}
      </p>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'

const render = ref(false)
const row = ref(null)
const props = defineProps(['visibleRowSet', 'index'])
const emit = defineEmits(['update:visibleRowSet'])
let observer = new IntersectionObserver(() => {}, {})

onMounted(() => {
  if (
    row.value &&
    row.value &&
    ((row as any).value as any)?.parentElement?.parentElement?.parentElement
  ) {
    const options = {
      root: ((row as any).value as any).parentElement.parentElement.parentElement,
      rootMargin: '100px',
      threshold: 0
    }
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          render.value = true
          if (row.value) {
            observer.unobserve(row.value)
          }
          emit('update:visibleRowSet', props.visibleRowSet + 1)
        }
      })
    }, options)
    if (row.value) {
      observer.observe(row.value)
    }
  }
})

onUnmounted(() => observer.disconnect)
</script>
