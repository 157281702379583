<template>
  <span :class="{ show: showToolTip }" ref="toolTip" class="tooltip">{{ tooltipText }}</span>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'ToolTipComponent',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tooltipText: '' as string,
      tooltipWidth: '0px' as string,
      mouseX: 0,
      mouseY: 0,
      x: '0px',
      y: '0px',
      showToolTip: false,
      timeout: setTimeout(() => {}, 0),
      maxTooltipWidth: ''
    }
  },
  mounted() {
    window.addEventListener('mousemove', (event: any) => {
      this.mouseX = event.pageX
      this.mouseY = event.pageY
    })
  },
  unmounted() {
    window.removeEventListener('mousemove', (event: any) => {
      this.mouseX = event.pageX
      this.mouseY = event.pageY
    })
  },
  watch: {
    tooltipText: {
      // Update element width on text change
      handler() {
        nextTick(() => {
          this.tooltipWidth =
            ((this.$refs.toolTip as HTMLElement)
              ? (this.$refs.toolTip as HTMLElement).scrollWidth
              : 0) + 'px'
        })
      },
      immediate: true
    },
    show: {
      handler() {
        if (this.showToolTip !== this.show) {
          if (!this.show) {
            // Apply Hide Aggressively
            this.showToolTip = this.show
          } else {
            // Apply show Hesitantly
            this.timeout = setTimeout(() => {
              if (!window.matchMedia('(hover: none)').matches) {
                // Prevents tooltips on mobile device click (touch) events
                this.showToolTip = this.show
                this.tooltipText = this.text
                this.x = this.mouseX + 'px'
                this.y = this.mouseY + 'px'
              }
            }, 400) // Human Pointer Settle Time Delay, prevents tooltip flickering, approx equal tooltip transition delay
          }
        } else {
          clearTimeout(this.timeout)
        }
      }
    }
  }
})
</script>

<style scoped>
.tooltip {
  --tooltip-width: v-bind(tooltipWidth);
  --mouse-x: v-bind(x);
  --mouse-y: v-bind(y);
  left: clamp(
    0.5rem,
    var(--mouse-x),
    100vw - (min(var(--tooltip-width), min(90dvw, 30rem)) + 0.5rem)
  );
  top: clamp(0.5rem, var(--mouse-y) - 2rem, 100vh - 2rem);
  top: clamp(0.5rem, var(--mouse-y) - 2rem, 100dvh - 2rem);
  pointer-events: none;
  position: fixed;
  z-index: 100;
  border-radius: 0.25rem;
  box-shadow: 0.1rem 0.35rem 1rem -0.3rem rgb(0 0 0 / 0.3);
  padding: 0.25rem 0.75rem;
  background-color: rgb(236 238 240 / 0.85);
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif;
  text-shadow: 0 0 0.2rem #ffffff;
  transition: opacity 0.25s ease-out;
  opacity: 0;
  max-width: min(90dvw, 30rem);
  text-overflow: ellipsis;
}

.tooltip.show {
  transition: opacity 0.25s ease-out 0.45s;
  opacity: 0.95;
}
</style>
