import csawfApi from '@/csawf-api'
import { LogMessageLevelEnum } from '@/api'

// a class to handle logging
export default class LoggingService {
  private static async log(level: LogMessageLevelEnum, message: string) {
    // also log to console
    message = `[${level}] ${message}`
    console.log(message)
    try {
      await csawfApi.logMessage(
        {
          level: level,
          message: message
        },
        csawfApi.axiosRequestConfigWithToken()
      )
    } catch (err) {
      console.error(`Failed to log message to backend:\n${message}`)
    }
  }
  public static async debug(message: string) {
    await LoggingService.log(LogMessageLevelEnum.Debug, message)
  }
  public static async info(message: string) {
    await LoggingService.log(LogMessageLevelEnum.Info, message)
  }
  public static async warning(message: string) {
    await LoggingService.log(LogMessageLevelEnum.Warning, message)
  }
  public static async error(message: string) {
    await LoggingService.log(LogMessageLevelEnum.Error, message)
  }
  public static async critical(message: string) {
    await LoggingService.log(LogMessageLevelEnum.Critical, message)
  }
}
