<template>
  <Transition>
    <div class="loading-spinner-div" v-if="show">
      <img
        id="spinner"
        data-testid="loading-spinner"
        class="loading-spinner"
        src="/spinner.svg"
        alt="loading"
      />
      <span
        v-if="itemsToUpdate && itemsToUpdate > 0"
        class="text-sm z-10 text-gray-800 drop-shadow-[0_0_3px_white]"
        style="text-shadow: 0 0 3px white"
        >{{ itemsUpdated }} of {{ itemsToUpdate }} items Processed...</span
      >
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
export default defineComponent({
  name: 'SpinnerComponent',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    itemsToUpdate: {
      type: Number as PropType<number | null | undefined>,
      default: 0
    },
    itemsUpdated: {
      type: Number as PropType<number | null | undefined>,
      default: 0
    }
  }
})
</script>

<style scoped>
.loading-spinner-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(75 75 75 / 25%);
  z-index: 9999999;
  backdrop-filter: blur(2px);
  overflow: hidden;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  position: absolute;
  width: 20%;
  pointer-events: none;
  user-select: none;
  max-width: 10rem;
  min-width: 4rem;
  opacity: 0.7;
}

/* Transition animation classes */
.v-enter-active,
.v-leave-active {
  transition: all 0.35s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
