<template>
  <div class="flex min-h-full overflow-auto justify-center">
    <div class="flex flex-col justify-evenly py-4 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <header-image class="flex h-auto px-2" />
      <div
        :class="{ 'logging-in': signingIn.value || false }"
        class="flex flex-col transition mt-6 mx-auto w-full max-w-sm lg:w-96 mb-10 sm:mb-20 md:mb-40"
      >
        <div>
          <div class="text-center">
            <p class="text-lg font-medium text-gray-700">Sign in</p>
          </div>
          <div class="text-center">
            <p class="error-text text-sm font-medium text-gray-700">
              {{ invalidCredentials.value ? 'Invalid Credentials' : '' }}
            </p>
          </div>

          <div>
            <form v-on:submit.prevent="login(user)" class="space-y-6">
              <!-- submit.prevents stops page from posting and refreshing -->
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Username</label>
                <div class="mt-1">
                  <input
                    :disabled="signingIn.value"
                    :class="{ 'error-shadow': invalidCredentials.value }"
                    v-model="user.name"
                    id="username"
                    name="username"
                    type="text"
                    autocapitalize="none"
                    autocomplete="text"
                    required
                    class="transition block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700"
                  >Password</label
                >
                <div class="mt-1">
                  <input
                    ref="passwordInput"
                    :disabled="signingIn.value"
                    :class="{ 'error-shadow': invalidCredentials.value }"
                    v-model="user.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="transition block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="flex items-center justify-evenly">
                <div class="flex items-center">
                  <input
                    :checked="saveUserName"
                    @input="saveUserName = ($event.target as HTMLInputElement).checked || false"
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900"
                    >Remember me</label
                  >
                </div>

                <!--                <div class="text-sm">-->
                <!--                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"-->
                <!--                    >Forgot your password?</a-->
                <!--                  >-->
                <!--                </div>-->
              </div>

              <div class="pt-6">
                <button
                  name="submit"
                  id="submit"
                  :disabled="signingIn.value"
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {{ signingIn.value ? 'Signing in...' : 'Sign in' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        style="background: url(/background.jpg); background-size: cover; background-position: left"
        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt="background"
      />
    </div>
  </div>
</template>

<script lang="ts">
import HeaderImage from '@/components/HeaderImage.vue'
import csawfApi from '@/csawf-api'
import router from '@/router'
import { defineComponent } from 'vue'
import { reactive } from 'vue'
import { showSpinner } from '@/composables'
import { errorHandler, isLoginError } from '@/services/ErrorHandler'

type User = { name: string; password: string }

export default defineComponent({
  name: 'LoginView',
  computed: {
    rememberMe: {
      get() {
        return localStorage.getItem('csawfRememberMe')
      },
      set(value: string | null) {
        if (value && value.length > 0) {
          localStorage.setItem('csawfRememberMe', value)
        } else {
          localStorage.removeItem('csawfRememberMe')
        }
      }
    }
  },
  components: {
    HeaderImage
  },
  data() {
    return {
      user: reactive({ name: '', password: '' }), // FormData Object
      invalidCredentials: reactive({ value: false }),
      signingIn: reactive({ value: false }),
      saveUserName: false as boolean
    }
  },
  methods: {
    async login(user: User) {
      try {
        this.setLoading(true)
        // Verify Credentials
        await csawfApi.login(user.name, user.password)
        this.invalidCredentials.value = false
        this.rememberMe = this.saveUserName ? user.name : null
        // Redirect User
        this.setLoading(false)
        this.redirect()
      } catch (error) {
        if (isLoginError(error)) {
          this.invalidCredentials.value = true
        } else {
          errorHandler(error)
        }
        this.setLoading(false)
      }
    },
    redirect() {
      router.push({ path: (this.$route?.query?.redirect as string) || '/' })
    },
    setLoading(loading: boolean) {
      showSpinner(loading)
      this.signingIn.value = loading
    }
  },
  created() {
    this.user.name = this.rememberMe || ''
    this.saveUserName = this.rememberMe !== null || false
  }
})
</script>

<style scoped>
.transition {
  transition:
    box-shadow 1s,
    opacity 1s;
}

.error-shadow {
  box-shadow: 0 0 0.5rem 0 red !important;
}

.logging-in {
  opacity: 40%;
  filter: blur(2px);
  pointer-events: none;
}

.error-text {
  color: red;
  height: 1.75rem;
  padding-top: 0.5rem;
}
</style>
