<script lang="ts">
export default {
  name: 'PageHeader',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
  setup() {}
}
</script>

<template>
  <header class="bg-white shadow">
    <div
      class="mx-auto max-w-7xl py-3 sm:py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between"
    >
      <h1 class="flex text-3xl font-bold tracking-tight text-gray-900">{{ text }}</h1>
      <slot>
        <!-- <page-header> Content Will Be Slotted In Here </page-header>-->
      </slot>
    </div>
  </header>
</template>
