<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="status-input-test-id"
  >
    <div class="flex rounded-md sm:max-w-md justify-center align-middle">
      <input :name="name" type="text" class="hidden w-0 h-0" :value="status || ''" />
      <component
        :class="'text-sm px-2 sm:px-2.5 py-0.5 sm:py-0.5 h-6 cursor-default'"
        :is="StatusLabelComponent"
        :propObject="{ label: status || '' }"
      />
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import StatusLabelComponent from '@/components/StatusLabels/BaseStatusLabelComponent.vue'
import QuestionMarkTipComponent from '@/components/QuestionMarkTipComponent.vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'StatusReadOnlyInput',
  components: { InputComponentBase, QuestionMarkTipComponent },
  computed: {
    StatusLabelComponent() {
      return StatusLabelComponent
    }
  },
  props: {
    status: {
      type: String as PropType<string | null>,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    name: {
      value: String,
      default: 'status'
    }
  }
})
</script>
