<script lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, UserIcon } from '@heroicons/vue/24/outline'
import router from '@/router'
import { type PropType, ref } from 'vue'
import type { LocalUser } from '@/services/DataServices'

export default {
  name: 'NavBar',
  props: {
    user: {
      type: Object as PropType<LocalUser>,
      required: true
    },
    navigation: {
      type: Array as PropType<{ name: string; href: string }[]>,
      required: true
    },
    userNavigation: {
      type: Array as PropType<{ name: string; href: string; external: boolean }[]>,
      required: true
    }
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    XMarkIcon,
    UserIcon
  },
  data() {
    return {
      href: ref(window?.location?.origin || '')
    }
  },
  methods: {
    router() {
      return router
    },
    isCurrentPage(currentPath: string, pagePath: string): boolean {
      return currentPath === pagePath
    },
    navigate(item: { name: string; href: string; external: boolean }) {
      if (item.external) {
        window.open(item.href, '_blank')
      } else {
        this.$router.push(item.href)
      }
    }
  }
}
</script>

<template>
  <Disclosure as="nav" class="bg-[#7e836c]" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <a :href="href">
              <img class="h-8 w-8" src="/logo.png" alt="CSA Logo" />
            </a>
          </div>
          <div class="hidden md:block">
            <!--  Navigation Buttons  -->
            <div class="ml-10 flex items-baseline space-x-4">
              <!--    IMPORTANT NOTE: use @click="$router.push(item.href)" NOT :href="item.href" OR APP WILL RESET!   -->
              <a
                class="cursor-pointer"
                v-for="item in navigation"
                :key="item.name"
                :id="item.name"
                @click="$router.push(item.href)"
                :class="[
                  isCurrentPage($route.path, item.href)
                    ? 'bg-[#636656] text-white hover:bg-[#8d9379]'
                    : 'text-gray-100 hover:bg-[#9ca386] hover:text-white',
                  'rounded-md px-3 py-2 text-sm font-medium'
                ]"
                :aria-current="isCurrentPage($route.path, item.href) ? 'page' : undefined"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Notification Button -->
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"-->
            <!--            >-->
            <!--              <span class="sr-only">View notifications</span>-->
            <!--              <BellIcon class="h-6 w-6" aria-hidden="true" />-->
            <!--            </button>-->

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center rounded-full bg-[#636656] text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span class="sr-only">Open user menu</span>
                  <span
                    v-tooltip="'Logged in as ' + user.userName"
                    class="hover:bg-[#9ca386] inline-flex h-8 w-8 items-center justify-center rounded-full bg-[#636656]"
                  >
                    <span
                      v-if="user.initials && user.initials.length > 0"
                      class="text-sm font-medium leading-none text-white"
                      >{{ user.initials }}</span
                    >
                    <div
                      class="text-white h-5 w-5"
                      v-if="!(user.initials && user.initials.length > 0)"
                    >
                      <user-icon />
                    </div>
                  </span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <!--    IMPORTANT NOTE: use @click="$router.push(item.href)" NOT :href="item.href" OR APP WILL RESET!   -->
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a
                      class="cursor-pointer"
                      @click="navigate(item)"
                      :class="[
                        active ? 'bg-[#636656] text-white hover:bg-[#8d9379]' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                    >
                      {{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md bg-[#636656] p-2 text-gray-100 hover:bg-[#9ca386] hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
        <!--    IMPORTANT NOTE: use @click="$router.push(item.href)" NOT :href="item.href" OR APP WILL RESET!   -->
        <DisclosureButton
          class="cursor-pointer"
          v-for="item in navigation"
          :key="item.name"
          as="a"
          @click="$router.push(item.href)"
          :class="[
            isCurrentPage($route.path, item.href)
              ? 'bg-[#636656] text-white hover:bg-[#8d9379]'
              : 'text-gray-100 hover:bg-[#9ca386] hover:text-white',
            'block rounded-md px-3 py-2 text-base font-medium'
          ]"
          :aria-current="isCurrentPage($route.path, item.href) ? 'page' : undefined"
        >
          {{ item.name }}
        </DisclosureButton>
      </div>
      <div class="border-t border-gray-700 pt-4 pb-3">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <span
              class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#636656]"
            >
              <span
                class="text-sm font-medium leading-none text-white"
                v-if="user.initials && user.initials.length > 0"
                >{{ user.initials }}</span
              >
              <div class="text-white h-5 w-5" v-if="!(user.initials && user.initials.length > 0)">
                <user-icon />
              </div>
            </span>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">{{ user.firstName }}</div>
            <div class="text-sm font-medium leading-none text-gray-400">{{ user.email }}</div>
          </div>

          <!-- Notification Button -->
          <!--          <button-->
          <!--            type="button"-->
          <!--            class="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"-->
          <!--          >-->
          <!--            <span class="sr-only">View notifications</span>-->
          <!--            <BellIcon class="h-6 w-6" aria-hidden="true" />-->
          <!--          </button>-->
        </div>
        <div class="mt-3 space-y-1 px-2">
          <!--    IMPORTANT NOTE: use @click="$router.push(item.href)" NOT :href="item.href" OR APP WILL RESET!   -->
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="a"
            @click="navigate(item)"
            class="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-[#9ca386] hover:text-white"
          >
            {{ item.name }}
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<style scoped>
.bg-csa {
  background-color: rgb(54 83 20);
}
</style>
