import { nextTick, onMounted, onUnmounted } from 'vue'
import type {
  LocalOrder,
  LocalOrderItem,
  LocalOrderItemStatusCount,
  LocalSku,
  CancelItemCountReturnType
} from '@/services/DataServices'
import type { MessageType } from '@/components/AlertMessageComponent.vue'
// Composables (Stateful logic management functions)
// https://vuejs.org/guide/reusability/composables.html#mouse-tracker-example
// Not 100% sure though

function createListener(targetFunction: EventListenerOrEventListenerObject, eventName: string) {
  // Create & remove event listeners for dialog key word
  onMounted(() => window.addEventListener(eventName, targetFunction))
  onUnmounted(() => window.removeEventListener(eventName, targetFunction))
}

function createGlobalDocumentListener(
  targetFunction: EventListenerOrEventListenerObject,
  eventName: string
) {
  // Create & remove event listeners for dialog key word
  onMounted(() => document.addEventListener(eventName, targetFunction, true))
  onUnmounted(() => document.removeEventListener(eventName, targetFunction, true))
}

function globalEvent(eventName: string, eventDetail: any) {
  // Create and emit new global event to open a new dialog from App.vue
  dispatchEvent(
    new CustomEvent(eventName, {
      detail: eventDetail,
      bubbles: true
    })
  )
}

export function closeDialog(id: number) {
  globalEvent('globalAppDialogEvent', id)
}

export function closeAllDialogs() {
  globalEvent('globalAppDialogEvent', undefined)
}

export function newDialog(
  targetDialog: string,
  dialogContent: any,
  editable: boolean = false,
  closeOnBackgroundClick?: boolean,
  id: number = Date.now()
) {
  // Create and emit new global event to open a new dialog from App.vue
  globalEvent('globalAppDialogEvent', {
    dialogTarget: targetDialog,
    dialogContent: dialogContent,
    editable: editable,
    closeOnBackgroundClick:
      closeOnBackgroundClick !== undefined ? closeOnBackgroundClick : !editable,
    id: id
  })
}

export function newSkuDialog(
  sku: LocalSku,
  itemIdAwaitingNewSKU?: number,
  editable?: boolean,
  closeOnBackgroundClick?: boolean
): void {
  newDialog(
    'viewSkuDialog',
    { sku: sku, itemIdAwaitingNewSKU: itemIdAwaitingNewSKU },
    editable ?? true,
    closeOnBackgroundClick ?? false
  )
}

export async function confirmDialog(
  label: string,
  message: string,
  alert?: { message: string; messageType?: MessageType }
): Promise<boolean> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListenerOrEventListenerObject = (event: any) => {
      window.removeEventListener('confirmDialogClosed', listener)
      resolve(event.detail.confirmed)
    }
    newDialog(
      'viewConfirmDialog',
      { label: label, message: message, alert: alert },
      false,
      true,
      ConfirmationDialogId
    )
    window.addEventListener('confirmDialogClosed', listener)
  })
}

export async function confirmNumberDialog(
  label: string,
  message: string,
  maxCount?: number
): Promise<{ confirmed: boolean; count: number }> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListenerOrEventListenerObject = (event: any) => {
      window.removeEventListener('confirmNumberDialogClosed', listener)
      resolve({ confirmed: event.detail.confirmed, count: event.detail.count })
    }
    newDialog(
      'viewConfirmNumberDialog',
      { label: label, message: message, maxCount: maxCount },
      false,
      true,
      ConfirmationDialogId
    )
    window.addEventListener('confirmNumberDialogClosed', listener)
  })
}

export async function confirmCancelStatusCountDialog(
  item: LocalOrderItem,
  message?: string,
  alert?: { message: string; messageType?: MessageType }
): Promise<CancelItemCountReturnType> {
  return new Promise((resolve) => {
    const ConfirmationDialogId: number = Date.now()
    const listener: EventListenerOrEventListenerObject = (event: any) => {
      window.removeEventListener('confirmCancelStatusCountDialogClosed', listener)
      resolve({
        confirmed: event.detail.confirmed,
        cancelEntireItem: event.detail.cancelEntireItem,
        statusCount: event.detail.statusCount
      })
    }
    newDialog(
      'viewConfirmCancelStatusCountDialogContent',
      { item: item, message: message, alert: alert },
      false,
      true,
      ConfirmationDialogId
    )
    window.addEventListener('confirmCancelStatusCountDialogClosed', listener)
  })
}

export function confirmDialogClose(confirmed: boolean) {
  // Create and emit new global event for confirmation dialog result
  globalEvent('confirmDialogClosed', { confirmed: confirmed })
}

export function confirmNumberDialogClose(confirmed: boolean, count?: number) {
  globalEvent('confirmNumberDialogClosed', { confirmed: confirmed, count: count })
}

export function confirmCancelStatusCountDialogClosed(
  confirmed: boolean,
  cancelEntireItem: boolean,
  statusCount: LocalOrderItemStatusCount | undefined
): void {
  globalEvent('confirmCancelStatusCountDialogClosed', {
    confirmed: confirmed,
    cancelEntireItem: cancelEntireItem,
    statusCount: statusCount
  })
}

export function createResizeListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'resize')
}

export function createGlobalDocumentScrollListener(
  targetFunction: EventListenerOrEventListenerObject
) {
  createGlobalDocumentListener(targetFunction, 'scroll')
}

export function createScrollListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'scroll')
}

export function createGlobalDocumentScrollWheelListener(
  targetFunction: EventListenerOrEventListenerObject
) {
  createGlobalDocumentListener(targetFunction, 'wheel')
}

export function createScrollWheelListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'wheel')
}

export function createKeyDownListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'keydown')
}

export function createDialogListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create Listener for dialog creation events (used in App.vue)
  createListener(targetFunction, 'globalAppDialogEvent')
}

export function createShowSpinnerListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'showGlobalLoadingSpinner')
}

export function showSpinner(show: boolean, totalNumberOfUpdates?: number) {
  // Create and emit new global event to open a new dialog from App.vue
  globalEvent('showGlobalLoadingSpinner', {
    showSpinner: show,
    totalNumberOfUpdates: totalNumberOfUpdates
  })
}

export async function runFunctionWithLoadingSpinner<T>(
  functionToRun: () => Promise<T>,
  numberOfUpdateEvents?: number
): Promise<T> {
  showSpinner(true, numberOfUpdateEvents)
  const response: any = await functionToRun()
  await nextTick() // waits for DOM to finish updating
  showSpinner(false)
  return response
}

export function createLoadingItemsUpdatedListener(
  targetFunction: EventListenerOrEventListenerObject
) {
  createListener(targetFunction, 'loadingItemsUpdated')
}

export function incrementLoadingItemsUpdatedListener() {
  globalEvent('loadingItemsUpdated', null)
}

export function createToolTipListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'showGlobalToolTip')
}

export function showToolTip(show: boolean, text?: string) {
  // Create and emit new global event to display a floating new tooltip from App.vue
  globalEvent('showGlobalToolTip', { show: show, text: text || '' })
}

export function orderUpdated(updatedOrder: LocalOrder) {
  // Create and emit new global event to update a local order variable
  globalEvent('orderUpdated', { order: updatedOrder })
}

export function createUpdatedOrderListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create & remove event listeners for successfully updated orders
  createListener(targetFunction, 'orderUpdated')
}

export function itemUpdated(item: LocalOrderItem) {
  // Create and emit new global event to update local order item
  globalEvent('itemUpdated', { item: item })
}

export function createUpdateditemListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create & remove event listeners for successfully updated item
  createListener(targetFunction, 'itemUpdated')
}

export function itemsUpdated() {
  // Create and emit new global event to update local order items
  globalEvent('itemsUpdated', {})
}

export function createUpdateditemsListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create & remove event listeners for successfully updated items
  createListener(targetFunction, 'itemsUpdated')
}

export function emitSkusUpdated() {
  // Create and emit new global event to update local skus variable
  globalEvent('skusUpdated', {})
}

export function createUpdatedSkusListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create & remove event listeners for successfully updated skus
  createListener(targetFunction, 'skusUpdated')
}

export function emitSkuUpdated(sku: LocalSku) {
  // Create and emit new global event to update local skus variable
  globalEvent('skuUpdated', { sku: sku })
}

export function createUpdatedSkuListener(targetFunction: EventListenerOrEventListenerObject) {
  // Create & remove event listeners for successfully updated skus
  createListener(targetFunction, 'skuUpdated')
}

export function emitAddNewSkuToItem(itemIdAwaitingNewSKU: number, sku: LocalSku) {
  globalEvent('newSkuForItemAdded', { itemID: itemIdAwaitingNewSKU, sku: sku })
}

export function createAddNewSkuToItemListener(targetFunction: EventListenerOrEventListenerObject) {
  createListener(targetFunction, 'newSkuForItemAdded')
}

export function createNewItemSkuListener(itemId: number | undefined, action: Function): void {
  const updateItemSKU = (event: Event) => {
    const detail = (event as CustomEvent).detail
    if (itemId && detail.itemID === itemId) action(detail.sku)
  }
  createAddNewSkuToItemListener(updateItemSKU)
}

export function newErrorDialog(errorName: string, errorMessage?: String, then?: Function) {
  newDialog('viewErrorDialog', { name: errorName, message: errorMessage, then: then }, false, false)
}

export function newNotificationDialog(message: string, title?: string) {
  newDialog('viewNotificationDialog', { title: title || '', message: message }, false, true)
}
