<template>
  <div
    id="table-navigation-container"
    v-if="pagesArray && pagesArray[0] && paginate"
    class="w-full p-4 mt-0.5 sm:mt-0 lg:pb-0 flex sm:justify-center self-center justify-between sm:self-end flex-wrap-reverse gap-1 sm:gap-8"
    :class="$props.class || ''"
  >
    <button
      :disabled="currentPageNumber === 1"
      type="button"
      @click="changePage(1)"
      class="truncate h-9 sm:h-7 self-center px-4 sm:px-4 w-auto rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
      :class="[
        { 'text-gray-400': currentPageNumber === 1 },
        {
          'text-gray-700 hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md':
            currentPageNumber !== 1
        }
      ]"
    >
      First
    </button>
    <button
      :disabled="currentPageNumber === 1"
      type="button"
      @click="changePage(currentPageNumber - 1)"
      class="truncate h-9 sm:h-7 self-center px-4 sm:px-4 w-auto rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
      :class="[
        { 'text-gray-400': currentPageNumber === 1 },
        {
          'text-gray-700 hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md':
            currentPageNumber !== 1
        }
      ]"
    >
      Previous
    </button>
    <div class="sm:inline-flex gap-4 hidden">
      <list-input
        class="w-full sm:w-fit flex self-center mb-0"
        slim-styling
        :editable="true"
        :options="pagesArray"
        :selected="currentPageNumber"
        @update:selected="changePage($event as number)"
        :max-height="'20rem'"
        :custom-place-holder-function="
          (label: string | number) => {
            return 'Page ' + label + ' of ' + totalPages
          }
        "
        :force-text-filter-shown="false"
      />
    </div>
    <button
      :disabled="currentPageNumber === totalPages"
      type="button"
      @click="changePage(currentPageNumber + 1)"
      class="truncate h-9 sm:h-7 self-center px-4 sm:px-4 w-auto rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
      :class="[
        { 'text-gray-400': currentPageNumber === totalPages },
        {
          'text-gray-700 hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md':
            currentPageNumber !== totalPages
        }
      ]"
    >
      Next
    </button>
    <button
      :disabled="currentPageNumber === totalPages"
      type="button"
      @click="changePage(totalPages)"
      class="truncate h-9 sm:h-7 self-center px-4 sm:px-4 w-auto rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
      :class="[
        { 'text-gray-400': currentPageNumber === totalPages },
        {
          'text-gray-700 hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md':
            currentPageNumber !== totalPages
        }
      ]"
    >
      Last
    </button>
    <div class="contents sm:hidden gap-4">
      <list-input
        class="w-full sm:w-fit flex pt-0.5 pb-2 justify-self-center"
        slim-styling
        :editable="true"
        :options="pagesArray"
        :selected="currentPageNumber"
        @update:selected="changePage($event as number)"
        :max-height="'20rem'"
        :custom-place-holder-function="
          (label: string | number) => {
            return 'Page ' + label + ' of ' + totalPages
          }
        "
        :force-text-filter-shown="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { showToolTip } from '@/composables'
import ListInput from '@/components/inputComponents/ListInput.vue'
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { Filter } from '@/services/DataServices'

export default defineComponent({
  name: 'TableNavigation',
  components: { ListInput },
  emits: ['update:currentPageNumber'],
  props: {
    currentPageNumber: {
      type: Number as PropType<number>,
      default: 0
    },
    totalPages: {
      type: Number as PropType<number>,
      default: 0
    },
    paginate: {
      type: Boolean,
      default: true
    },
    class: {
      type: String,
      default: ''
    }
  },
  computed: {
    pagesArray() {
      let arr = [] as number[]
      if (this.paginate) {
        for (let i = 1; i <= this.totalPages; i++) {
          arr.push(i)
        }
      }
      return arr
    }
  },
  methods: {
    changePage(pageNumber: number) {
      this.$emit('update:currentPageNumber', pageNumber)
    },
    FunnelIcon,
    showToolTip,
    updateFilter(filter: Filter, event: string) {
      filter.activeFilterIndex = filter.propertyFilterValues.indexOf(event)
    }
  }
})
</script>
