<template>
  <button v-if="tipText" class="contents" type="button" aria-label="tooltip">
    <span
      @click="showToolTip()"
      class="hover:drop-shadow-[0_0_4px_rgb(29_78_216_/_100%)] drop-shadow-[0_0_4px_rgb(29_78_216_/_60%)] select-none text-base sm:text-sm hover:text-indigo-600 text-indigo-500 cursor-pointer px-2 sm:px-1 font-bold"
      >?</span
    >
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { newNotificationDialog } from '@/composables'
export default defineComponent({
  name: 'QuestionMarkTipComponent',
  props: { tipText: { type: String } },
  data() {
    return { show: false }
  },
  methods: {
    showToolTip() {
      if (this.tipText) newNotificationDialog(this.tipText)
    }
  }
})
</script>
