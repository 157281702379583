export enum FileType {
  TXT = 'text/plain;charset=utf-8',
  DXF = 'text/plain;charset=utf-8',
  JSON = 'application/json',
  CSV = 'text/csv',
  ZIP = 'application/zip'
}

export class Downloader {
  file: Blob
  url: string
  a?: HTMLAnchorElement
  filename: string
  type: FileType

  constructor(data: any, filename?: string, type?: FileType) {
    this.type = type || FileType.TXT
    this.file = new Blob([data], { type: this.type })
    this.url = URL.createObjectURL(this.file)
    this.filename = this.getFileName(filename, type)
  }

  public get fileName(): string {
    return this.filename
  }
  public get fileType(): FileType {
    return this.type
  }
  public get fileBlob(): Blob {
    return this.file
  }
  public get fileUrl(): string {
    return this.url
  }

  async initiateDownload(): Promise<void> {
    this.appendAnchor()
    if (!this.a) throw new Error('Download Anchor element not created.')
    this.a.click()
    await this.removeAnchor()
  }

  private appendAnchor(): void {
    this.a = document.createElement('a')
    this.a.href = this.url
    this.a.download = this.filename
    if (!document.body) throw new Error('Document body not found.')
    document.body.appendChild(this.a)
  }

  private async removeAnchor(): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout((): void => {
        if (this.a) {
          document.body.removeChild(this.a)
          window.URL.revokeObjectURL(this.url)
        }
        resolve()
      }, 1)
    })
  }

  private getFileName(fileName?: string, type?: FileType): string {
    fileName = fileName ? fileName : 'CSA_DOWNLOAD'
    return fileName + '_' + this.getDateString() + this.getAppendix(type)
  }

  private getAppendix(type?: FileType): string {
    switch (type) {
      case FileType.CSV:
        return '.csv'
      case FileType.DXF:
        return '.dxf'
      case FileType.JSON:
        return '.json'
      case FileType.ZIP:
        return '.zip'
      default:
        return '.txt'
    }
  }

  private getDateString(): string {
    const dateString: string = new Date().toLocaleString()
    return dateString.replace(/[/:]/g, '-').replace(/[ ,]/g, '_')
  }
}

export function download(data: any, fileName?: string, type?: FileType): void {
  new Downloader(data, fileName, type).initiateDownload().then()
}
