<template>
  <td
    v-if="!arrayIncludesStringLike(hideCells, cell)"
    :class="[
      'whitespace-nowrap py-1 text-sm',
      tableCellClasses,
      { 'hide-if-small-screen': arrayIncludesStringLike(smallScreenHiddenFields, cell) }
    ]"
    :style="styleOverride || ''"
  >
    <slot></slot>
  </td>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue'
import { arrayIncludesStringLike } from '@/services/DataServices'

const props = defineProps({
  styleOverride: {
    default: '',
    type: String
  },
  firstCell: {
    type: Boolean,
    default: false
  },
  hideCells: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  smallScreenHiddenFields: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  cell: {
    type: String,
    default: ''
  }
})

let tableCellClasses = props.firstCell
  ? 'text-center pl-0 sm:pl-3 pr-0 sm:pr-3 text-gray-500'
  : 'mr-1 px-0.5 sm:px-2 text-gray-900'
</script>
