<template>
  <div class="contents sm:flex gap-4 ml-auto sm:pl-4">
    <!-- ROW FILTER DROP DOWN LISTS -->
    <div data-testid="table-filter-lists" class="contents sm:inline-flex gap-4 sm:ml-auto">
      <list-input
        v-for="filter in filters"
        :key="JSON.stringify(filter)"
        class="w-full sm:w-fit flex sm:pb-0 pb-1 justify-self-center"
        slim-styling
        :editable="true"
        :options="filter.propertyFilterValues"
        :selected="filter.propertyFilterValues[filter.activeFilterIndex]"
        @update:selected="updateFilter(filter, $event)"
        :max-height="'20rem'"
        :custom-icon="FunnelIcon"
        :force-text-filter-shown="filter.listFilterable"
      />
    </div>

    <!-- ROW TEXT FILTER TEXT INPUT & RESET BUTTON -->
    <div class="contents sm:inline-flex gap-4 sm:ml-auto">
      <div
        v-if="showTextInputFilter !== false"
        class="w-full sm:w-auto flex self-center pt-2 sm:pt-0"
      >
        <form class="w-full" @submit.prevent autocomplete="off">
          <input
            id="table-text-filter-input"
            ref="tableFilterTextInput"
            style="
              -webkit-margin-after: revert;
              -webkit-margin-before: revert;
              -webkit-padding-after: revert;
              -webkit-padding-before: revert;
            "
            type="text"
            class="shadow-inner bg-gray-50 w-full sm:w-auto text-center focus:text-left text-gray-700 hover:bg-gray-50 hover:text-black h-9 sm:h-7 self-center px-4 sm:px-4 rounded-md text-sm border-gray-300 focus:ring-indigo-600"
            @keyup.enter.prevent="filterByText($event)"
            @submit.prevent="filterByText($event)"
            :placeholder="'Filter Results'"
            @focusout="unfocus($event)"
          />
        </form>
      </div>

      <div
        v-if="showTextInputFilter !== false || filters.length > 1"
        class="w-full sm:w-auto flex mt-1.5 sm:mt-0 pt-1.5 sm:pt-0"
      >
        <button
          data-testid="table-filter-clear-button"
          type="button"
          @click="clearFilters()"
          class="truncate w-full sm:w-auto text-gray-700 hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md h-9 sm:h-7 self-center px-4 sm:px-4 rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
        >
          Clear Filters
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { showToolTip } from '@/composables'
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { Filter } from '@/services/DataServices'
import ListInput from '@/components/inputComponents/ListInput.vue'

export default defineComponent({
  name: 'TableFilterInput',
  components: { ListInput },
  emits: ['update:clearFilters', 'update:filterByText'],
  props: {
    filters: {
      type: Array as PropType<Filter[]>,
      required: true
    },
    showTextInputFilter: {
      type: Boolean as PropType<boolean | undefined>
    }
  },
  data() {
    return {
      currentFilter: '' as string
    }
  },
  methods: {
    FunnelIcon,
    showToolTip,
    clearFilters() {
      const textInput: HTMLInputElement = this.$refs.tableFilterTextInput as HTMLInputElement
      if (textInput) {
        textInput.value = ''
      }
      this.currentFilter = ''
      this.$emit('update:clearFilters')
    },
    filterByText(event: any) {
      if (event && event.target) {
        this.currentFilter = event.target.value || ''
        event.target.blur()
        this.$emit('update:filterByText', this.currentFilter)
      }
    },
    updateFilter(filter: Filter, event: string) {
      filter.activeFilterIndex = filter.propertyFilterValues.indexOf(event)
    },
    unfocus(event: Event) {
      const target = event.target as HTMLInputElement
      if (target && target.value) {
        target.value = this.currentFilter
      }
    }
  }
})
</script>
