<template>
  <img src="/Custom_Steel_Appeal_Logo.png" class="header-image" alt="Custom Steel Appeal" />
</template>

<script lang="ts">
export default {
  name: 'HeaderImage'
}
</script>

<style scoped>
.header-image {
  display: flex;
  align-self: center;
  width: 350px;
}
</style>
