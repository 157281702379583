<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="check-box-input-test-id"
    :user-input-visible="editable"
    :show-divider="showDivider"
  >
    <input
      :name="name"
      v-if="editable"
      type="checkbox"
      :disabled="!editable"
      :checked="checked ? checked : false"
      @input="updateChecked($event)"
      class="h-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 p-[clamp(0.6rem,_3vw,_1rem)] sm:p-0"
    />
    <div v-if="!editable && !showYesNo" class="flex justify-center">
      <span class="w-0 h-0 p-0 m-0 overflow-hidden opacity-0">{{
        checked ? 'true' : 'false'
      }}</span>
      <CheckCircleIcon
        v-if="checked ? checked : false"
        class="text-lime-500 flex w-6 h-6"
      ></CheckCircleIcon>
      <x-circle-icon
        v-if="checked ? !checked : true"
        class="text-neutral-500 flex w-6 h-6"
      ></x-circle-icon>
    </div>
    <label v-if="showYesNo" class="px-4 select-none font-medium text-gray-500">{{
      checked ? 'Yes' : 'No'
    }}</label>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'
export default defineComponent({
  name: 'CheckBoxInput',
  components: {
    InputComponentBase,
    CheckCircleIcon,
    XCircleIcon
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    showYesNo: {
      type: Boolean as PropType<boolean | null>,
      default: false
    },
    checked: {
      type: Boolean as PropType<boolean | null>,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    name: {
      value: String,
      default: 'check-box-input'
    }
  },
  methods: {
    updateChecked(event: any) {
      this.$emit('update:checked', ((event as InputEvent).target as HTMLInputElement).checked) // for v-model:checked="boundValue" and @update:checked="boundValue = $event"
    }
  }
})
</script>
