<template>
  <div
    class="inline-flex items-center gap-4 rounded-xl py-0.5 px-2"
    :class="background"
    :style="style"
  >
    <component :is="iconComponent" class="text-white flex w-8 h-8 drop-shadow-lg" />
    <div
      class="w-full flex justify-center min-h-8 items-center bg-white pl-8 ml-[-0.5rem] pr-8 mr-[-0.375rem] rounded-[0.6rem] max-w-[calc(100%_-_2.125rem)]"
    >
      <p
        class="pt-[0.1rem] pb-[0.15rem] whitespace-pre-line text-sm text-gray-600 leading-5 font-semibold"
      >
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  BellAlertIcon,
  InformationCircleIcon
} from '@heroicons/vue/24/solid'

export enum MessageTypes {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION'
}

export type MessageType =
  | MessageTypes.ERROR
  | MessageTypes.WARNING
  | MessageTypes.MESSAGE
  | MessageTypes.NOTIFICATION

export default defineComponent({
  name: 'AlertMessageComponent',
  components: {
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    BellAlertIcon,
    InformationCircleIcon
  },
  props: {
    message: {
      default: '',
      type: String
    },
    messageType: {
      type: String as PropType<MessageType>,
      default: MessageTypes.MESSAGE
    },
    style: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      MessageTypes
    }
  },
  computed: {
    isNotification() {
      return this.messageType === MessageTypes.NOTIFICATION
    },
    isError() {
      return this.messageType === MessageTypes.ERROR
    },
    isWarning() {
      return this.messageType === MessageTypes.WARNING
    },
    isMessage() {
      return this.messageType === MessageTypes.MESSAGE
    },
    background() {
      return this.isError
        ? 'bg-rose-500/[0.5]'
        : this.isWarning
          ? 'bg-amber-400/[0.5]'
          : this.isNotification
            ? 'bg-lime-500/[0.5]'
            : 'bg-blue-400/[0.5]'
    },
    iconComponent() {
      return this.isError
        ? ExclamationTriangleIcon
        : this.isWarning
          ? ExclamationCircleIcon
          : this.isNotification
            ? BellAlertIcon
            : InformationCircleIcon
    }
  }
})
</script>
