<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="status-count-input-test-id"
    :show-divider="showDivider"
  >
    <div
      class="flex-col inline-flex gap-3 flex-wrap rounded-md sm:max-w-md justify-center align-middle"
    >
      <div
        v-for="orderItemStatusCount in item.order_item_status_counts"
        :key="orderItemStatusCount?.status"
        class="flex items-center justify-center"
      >
        <input
          :name="name"
          type="text"
          class="hidden w-0 h-0"
          :value="orderItemStatusCount?.status || ''"
        />
        <component
          :class="'text-sm px-2 sm:px-2.5 py-0.5 sm:py-0.5 h-6 cursor-default'"
          :is="StatusLabelComponent"
          :propObject="{
            label: orderItemStatusCount?.status || '',
            count: totalCount > 1 ? orderItemStatusCount?.count : undefined
          }"
        />
      </div>
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StatusLabelComponent from '@/components/StatusLabels/BaseStatusLabelComponent.vue'
import { LocalOrderItem } from '@/services/DataServices'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'OrderItemStatusCountReadOnlyInput',
  components: { InputComponentBase },
  computed: {
    StatusLabelComponent() {
      return StatusLabelComponent
    },
    totalCount() {
      if (!this.item.order_item_status_counts) return 0
      return this.item.order_item_status_counts?.reduce((sum, stsCt) => sum + (stsCt.count || 0), 0)
    },
    label(): string {
      return `Item Product Status${this.totalCount > 1 ? 'es' : ''}`
    },
    tooltip() {
      return this.totalCount > 1
        ? 'The Statuses For Each Of The Products Represented By This Order Item'
        : "The Status For This Order Item's Product"
    }
  },
  props: {
    item: {
      type: LocalOrderItem,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    name: {
      value: String,
      default: 'status-count'
    }
  }
})
</script>
