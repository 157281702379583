<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="text-input-test-id"
    :user-input-visible="editable"
    :disabled="disabled"
    :show-divider="showDivider"
  >
    <div
      :class="[
        {
          'w-full shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600':
            editable || false
        },
        { 'bg-gray-100': disabled && editable }
      ]"
      class="flex rounded-md sm:max-w-md justify-center"
    >
      <span
        v-if="!editable"
        class="px-3 sm:text-sm py-1.5 cursor-text max-w-full"
        :class="[{ 'text-gray-400': disabled }, { 'text-gray-900': !disabled }]"
        :style="inputStyleOverride"
        >{{ text }}</span
      >
      <input
        v-if="editable"
        :autocomplete="autocomplete"
        :name="name"
        :id="id as string"
        :required="required"
        type="text"
        class="truncate text-center block flex-1 border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-5"
        :class="[
          { 'text-gray-400': disabled },
          { 'text-gray-900': !disabled },
          { 'pointer-events-none': disabled }
        ]"
        placeholder=""
        @input="updateText"
        :value="(text as string) || ''"
        :style="inputStyleOverride"
      />
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'TextInput',
  components: { InputComponentBase },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    text: {
      type: [String, Number] as PropType<string | number | null>,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number] as PropType<string | number>,
      default: ''
    },
    name: {
      type: String,
      default: 'text-input'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    nameOnlyFilter: {
      // Only allow characters in input
      type: Boolean,
      default: false
    },
    numberOnlyFilter: {
      // Only allow numbers in input
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputStyleOverride: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  methods: {
    updateText(event: Event) {
      // const inputValue =  ((event as InputEvent).target as HTMLInputElement).value
      // const value = this.nameOnlyFilter ? ((event as InputEvent).target as HTMLInputElement).value :
      if (this.nameOnlyFilter) {
        this.filterNameInput((event as InputEvent).target as HTMLInputElement)
      } else if (this.numberOnlyFilter) {
        this.filterNumberInput((event as InputEvent).target as HTMLInputElement)
      }

      this.$emit('update:text', ((event as InputEvent).target as HTMLInputElement).value) // for v-model:text="boundValue" and @update:text="boundValue = $event"
    },

    filterNameInput(input: HTMLInputElement) {
      this.filter(input, /[^a-zA-Z\d -]/g)
    },

    filterNumberInput(input: HTMLInputElement) {
      this.filter(input, /[^\d]/g)
    },

    filter(input: HTMLInputElement, reg: RegExp) {
      const pos = input.selectionStart || 0
      if (reg.test(input.value)) {
        input.value = input.value.replace(reg, '')
        input.selectionStart = pos - 1
        input.selectionEnd = pos - 1
      }
    }
  }
})
</script>
