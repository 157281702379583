import csawfApi from '@/csawf-api'
import { closeAllDialogs, newErrorDialog, showToolTip } from '@/composables'
import LoggingService from '@/services/LoggingService'
import { type ComponentPublicInstance } from 'vue'
import router from '@/router'
import * as Sentry from '@sentry/vue'
import { capitalize, stringLabelCompare } from '@/services/DataServices'
import type { SeverityLevel } from '@sentry/types/build/types/severity'

export function errorHandler(error: any, forceLogoutOnAuthenticationFail: boolean = true) {
  // Logout on authentication failure unless otherwise specified
  // Required by Axios to make some properties accessible (LIKE STATUS..) https://axios-http.com/docs/handling_errors
  // otherwise must use JSON.parse(JSON.stringify(error)).response.status for errors returned from the front end (eg: network error)
  // Check for custom error message returned from backend
  const axiosMessage: boolean =
    error?.response?.data?.message && error.response.data.message.length > 1
  const response: string = axiosMessage
    ? capitalize(error.response.data.label) + '\n' + error.response.data.message
    : 'No Error Message Provided - ' + error.message
  // Add custom error to parsedError if it exists
  error.message = axiosMessage ? error.response.data.message + ' ' + error.message : error.message

  const status = error?.status ?? error?.response?.status ?? undefined

  const forceLogOut: boolean = forceLogoutOnAuthenticationFail && (status === 401 || status === 403)

  // Log all non-global errors to Sentry (global errors are already captured in main.ts should already be captured)
  if (error.global === undefined || error.global === false) {
    if (forceLogOut) HandledException(error, 'Logged Invalid Authentication', 'log')
    else HandledException(error)
  }

  if (
    status == null &&
    ((error.message && error.message === 'Network Error') ||
      (error.code && error.code === 'ERR_NETWORK'))
  ) {
    // SERVER CONNECTION ERROR
    newErrorDialog('Connection Error', 'Failed to connect to the server, please try again later.')
  } else if (status === 401 || status === 403) {
    if (
      error.config &&
      error.config.headers &&
      error.config.headers.Authorization &&
      error.config.headers.Authorization.length > 1
    ) {
      // INVALID TOKEN
      if (forceLogoutOnAuthenticationFail) {
        // Token not authenticated
        csawfApi.logout()
      } else {
        // User allowed to access page - just not this data specifically
        logWarning('Unauthorized: ' + sanitiseError(error))
        newErrorDialog(
          'Server Authentication Failed',
          'You do not have the required privileges to view this content.'
        )
      }
    } else {
      logWarning('Unauthorized: ' + sanitiseError(error))
      newErrorDialog('Authentication Error', error.message || 'Failed to authenticate request.')
    }
  } else if (status === 400) {
    // BAD REQUEST
    logError(
      (axiosMessage ? 'Bad Request: ' : 'No Error Message Provided! ') + sanitiseError(error)
    )
    newErrorDialog('Server Request Error:', response)
  } else if (status === 429) {
    // REQUEST THROTTLED
    logError('Request Throttled: ' + sanitiseError(error))
    newErrorDialog(
      'Maximum Request Rate Exceeded:',
      'Too many consecutive requests, please wait before trying again.'
    )
  } else if (status === 500) {
    // BAD RESPONSE
    logError(
      (axiosMessage ? 'Bad Response: ' : 'No Error Message Provided! ') + sanitiseError(error)
    )
    newErrorDialog('Server Response Error:', response)
  } else if (status === 9999) {
    // CUSTOM ERROR
    logError('Custom Error: ' + sanitiseError(error))
    newErrorDialog(error.name ?? 'Internal System Error', error.message, error.then)
  } else {
    // UNKNOWN ERROR
    const name: string = error?.name ?? 'Unknown Error'
    logError(`${name}: ${sanitiseError(error)}`)
    newErrorDialog(name, `An Unrecognised Error Occurred And Was Logged.\n${sanitiseError(error)}`)
  }
}

function HandledException(error: any, name?: string, level?: SeverityLevel) {
  // Sets Sentry issue title to 'HandledException' and name to error.name/'Handled Error'
  if (name) error.name = name
  Sentry.captureException(error, (scope) => {
    scope.setTransactionName(error.name ?? 'Handled Error')
    if (level) scope.setLevel(level)
    return scope
  })
}

export function throwError(
  errorName: any,
  errorMessage?: any,
  errorStack?: any,
  then?: Function,
  global: boolean = false
): void {
  // Allows for custom error messages to be thrown as throwError(title, message, stack)
  // and
  // Allows throwError(e), throwError(name,e), throwError(name,message,e) to be used in catch blocks
  showToolTip(false)
  let name: string = ''
  let message: string = ''
  let stack: string | undefined = ''
  if (errorMessage) {
    name = typeof errorName === 'string' ? errorName : errorName.name || 'Internal System Error'
    message =
      typeof errorMessage === 'string'
        ? errorMessage + getMessageFromError(errorName)
        : errorMessage.response?.data?.message ||
          errorMessage.message ||
          errorName.response?.data?.message ||
          errorName.message ||
          sanitiseError(errorMessage)
    stack = getStack(errorStack) || getStack(errorMessage) || getStack(errorName)
  } else {
    name = 'Internal System Error'
    message =
      typeof errorName === 'string' ? errorName : errorName.message || sanitiseError(errorName)
    stack = getStack(errorName)
  }

  errorHandler({
    name: name,
    message: message,
    stack: stack,
    status: 9999,
    then: then,
    global: global
  })
}

function getMessageFromError(e: any): string {
  if (!(e?.message || e?.data?.message)) return ''
  return '\n\n' + (e?.message || e?.data?.message || '')
}

function logWarning(errorString: string): void {
  LoggingService.warning(errorString).then()
}

export function logSanitizedError(error: any): void {
  LoggingService.warning(sanitiseError(error)).then()
}

function logError(errorString: string, then?: Function): void {
  if (LoggingService) {
    LoggingService.error(errorString).then(() => {
      if (then) then()
    })
  } else {
    console.log(errorString)
  }
}

function sanitiseError(error: any): string {
  // Only return select error fields if possible, excluding potentially sensitive data
  const name: string = '\n' + (error.name ? 'Name: "' + error.name + '" ' : '""') + '\n'
  const message: string = (error.message ? 'Message: "' + error.message + '" ' : '""') + '\n'
  const errorError: string = (error.error ? 'Error: ' + error.error + ' ' : '') + '\n'
  const stack: string = getStack(error) ? 'Stack:\n ' + getStack(error) : ''
  const raw: string | undefined =
    message === '' && errorError === '' && stack === '' ? JSON.stringify(error) : undefined
  return raw || name + message + errorError + stack
}

export function isLoginError(e: any): boolean {
  const status = e?.response?.status ?? e?.status ?? undefined
  const message_type = e?.response?.data?.message_type ?? e?.data?.message_type ?? undefined
  return status === 401 || status === 403 || stringLabelCompare(message_type, 'ValidationError')
}

function getStack(e: any): string | undefined {
  return e?.stack ?? e?.data?.stack ?? e?.response?.data?.stack ?? e?.response?.stack ?? undefined
}

export function globalErrorHandler(err: any, vm: ComponentPublicInstance | null, info: string) {
  const errorName: string = 'Unknown Error'
  const errorMessage: string =
    'An Unrecognised Error Occurred And Was Logged.\n' +
    (err.name + ': ' || '') +
    (err.message || 'An unknown global error was detected and logged.')
  const errorStack: string = (err.stack ? 'Stack:' + err.stack + '\n' : '') + info || ''
  const onClose = () => {
    // Refresh On Error Close
    router.go(0)
  }
  // Close other dialogs and ToolTips
  showToolTip(false)
  closeAllDialogs()
  throwError(errorName, errorMessage, errorStack, onClose, true)
}
