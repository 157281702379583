import { createApp, type ComponentPublicInstance } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'
import { errorHandler, globalErrorHandler } from '@/services/ErrorHandler'
import { showToolTip } from '@/composables'
import * as Sentry from '@sentry/vue'
import { api } from './services/DataServices'
import { type SentryConfig } from '@/api'

// If this is a development environment, load the mock service worker
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

const app = createApp(App)

async function sentryInit() {
  // Initialize sentry if not running local tests (api functions will be undefined in local tests)
  try {
    const cfg: SentryConfig | undefined = api?.getSentryCfg ? await api.getSentryCfg() : undefined
    if (cfg?.frontend_sentry_dsn) {
      Sentry.init({
        app,
        environment: cfg.environment_type,
        dsn: cfg.frontend_sentry_dsn,
        integrations: [Sentry.browserTracingIntegration({ router })],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      })
      app.config.errorHandler = (err: any, vm: ComponentPublicInstance | null, info: string) => {
        Sentry.captureException(err)
        globalErrorHandler(err, vm, info)
      }
    } else {
      defaultHandlerInit()
    }
  } catch (e) {
    defaultHandlerInit()
    if (errorHandler) errorHandler(e)
  }
}

function defaultHandlerInit(): void {
  // Initialize global error handler without sentry.
  app.config.errorHandler = (err: any, vm: ComponentPublicInstance | null, info: string) => {
    globalErrorHandler(err, vm, info)
  }
}

app.directive('tooltip', (el, binding) => {
  // Use with <el v-tooltip="'text'" />
  ;(el as HTMLElement).onmouseenter = () => {
    showToolTip(binding.value !== undefined && binding.value.length > 0, binding.value || '')
  }
  ;(el as HTMLElement).onmouseleave = () => {
    showToolTip(false)
  }
})

// Following Required for static reference (outside components)
app.config.globalProperties.$globalUpdatingFlag = false as boolean
export const getGlobalUpdatingFlag = (): boolean => app.config.globalProperties.$globalUpdatingFlag
export const setGlobalUpdatingFlag = (updating: boolean): void => {
  app.config.globalProperties.$globalUpdatingFlag = updating
}

export const clearGlobalData = (): void => {
  app.config.globalProperties.$globalUpdatingFlag = null
}

sentryInit().then(() => {
  app.use(router)
  app.mount('#app')
})

window.addEventListener('click', () => {
  showToolTip(false)
})
