<template>
  <form
    v-on:submit.prevent="onSubmit()"
    class="flex flex-col dialog-form max-w-[40rem] overflow-hidden"
    data-testid="dialog-form-test-id"
  >
    <!-- Hide initial focus, particularly for mobile devices -->
    <input style="max-width: 0; max-height: 0; overflow: hidden" aria-hidden="true" type="button" />
    <DialogTitle
      as="h2"
      class="text-center sm:text-left max-h-20 overflow-auto m-4 mx-8 bg-white flex flex-none items-center justify-center text-lg font-semibold text-gray-900"
      >{{ dialogTitle }}</DialogTitle
    >
    <div
      style="overscroll-behavior: contain"
      class="overflow-auto sm:break-words dialog-form-content px-1 sm:px-6 w-full"
      data-testid="dialog-content-test-id"
    >
      <div class="text-center max-h-fit">
        <div class="overflow-y-visible overflow-x-hidden break-words py-1 max-h-fit">
          <slot>
            <!-- <dialog-content> Content Will Be Slotted In Here </dialog-content>-->
          </slot>
        </div>
      </div>
    </div>
    <div
      class="w-full mb-0 p-5 px-6 mt-0 flex justify-center self-baseline sm:self-end flex-wrap-reverse gap-4 sm:gap-8"
    >
      <div
        v-for="button in buttons as DialogButton[]"
        :class="{ hidden: button.condition === false }"
        :key="button.label"
      >
        <button
          v-if="button.condition !== false"
          data-testid="dialog-button"
          :id="button.id"
          v-tooltip="button.tooltip"
          :disabled="!!button.disabled || !!button.loading"
          :type="button.type || 'button'"
          @click="button.onClick === undefined ? () => {} : button.onClick()"
          class="global-button w-auto inline-flex justify-center text-white rounded-md text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          :class="
            !!button.disabled || !!button.loading
              ? ' bg-gray-500 hover:bg-gray-500 opacity-25'
              : 'bg-indigo-600 hover:bg-indigo-500'
          "
        >
          {{ button.loading ? 'Loading Data...' : button.label }}
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { DialogTitle } from '@headlessui/vue'

export interface DialogButton {
  onClick?: Function
  label: string
  type?: 'reset' | 'submit' | 'button' | undefined
  condition?: boolean
  disabled?: boolean
  tooltip?: string
  id?: string
  loading?: boolean
}
export default defineComponent({
  name: 'DialogContent',
  components: { DialogTitle },
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },
    dialogTitle: {
      type: String,
      required: true
    },
    buttons: {
      type: Array as PropType<DialogButton[]>,
      required: true
    }
  }
})
</script>

<style scoped>
.dialog-form {
  max-height: max(100vh, 16rem);
  max-height: max(100dvh, 16rem);
}

.dialog-form-content {
  max-height: none;
}

@media (min-width: 640px) {
  .dialog-form {
    max-height: clamp(16rem, 90vh, 50rem);
    max-height: clamp(16rem, 90dvh, 50rem);
  }

  .dialog-form-content {
    max-height: max(min(calc(100vh - 4rem), 60rem));
    max-height: max(min(calc(100dvh - 4rem), 60rem));
  }
}
</style>
