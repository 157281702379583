<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="number-input-test-id"
    :user-input-visible="editable"
    :show-divider="showDivider"
    :disabled="disabled"
  >
    <div
      data-testid="number-input-value-test-id"
      :class="[
        {
          'shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600':
            editable || false
        },
        { 'bg-gray-100': disabled && editable }
      ]"
      class="flex rounded-md max-w-2xl w-full"
    >
      <span
        v-if="!editable"
        class="flex items-center px-3 sm:text-sm py-1.5 w-full justify-center"
        :class="[{ 'text-gray-400': disabled }, { 'text-gray-900': !disabled }]"
        :style="inputStyleOverride"
      >
        {{ numberPropVal.toString() }}
      </span>
      <input
        v-if="editable"
        :name="name"
        :id="id as string"
        :required="required"
        :disabled="!editable || disabled"
        type="number"
        class="max-w-full px-2 py-1.5 pl-6 sm:py-1.5 text-center block flex-1 border-0 bg-transparent placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-5"
        :class="[
          { 'text-gray-400': disabled && editable },
          { 'text-gray-900': !disabled || !editable }
        ]"
        :placeholder="placeholder?.toString() || '1'"
        @keydown="sanitizeKeyPressOperators($event)"
        @input="updateNumber"
        @change="updateNumber"
        :value="inputValueDefault"
        :style="inputStyleOverride"
        @focusout="unFocus"
      />
      <div
        v-if="editable && !isNaN(Number(max)) && showOfMax"
        :style="'pointer-events: none; margin-left: -' + (max.toString().length * 0.5 + 4) + 'rem'"
        data-testid="number-input-of-test-id"
      >
        <span
          class="flex items-center px-3 sm:text-sm py-1.5 w-full justify-center text-gray-400 font-medium pointer-events-none select-none"
        >
          {{ 'of ' + max.toString() }}
        </span>
      </div>
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'NumberInput',
  components: { InputComponentBase },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Number
    },
    number: {
      type: [Number, String] as PropType<string | number>,
      default: 1
    },
    label: {
      type: String,
      default: ''
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number] as PropType<string | number>,
      default: ''
    },
    name: {
      type: String,
      default: 'number-input'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9999999999999
    },
    showOfMax: {
      type: Boolean,
      default: false
    },
    inputStyleOverride: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      required: false
    },
    allowOperators: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    unFocus(event: Event) {
      const value: string = ((event as InputEvent).target as HTMLInputElement).value || ''
      if (value === '') {
        const valueNum: number = Number(value)
        ;((event as InputEvent).target as HTMLInputElement).value = this.min?.toString()
        this.$emit('update:number', valueNum) // for v-model:text="boundValue" and @update:text="boundValue = $event"
      }
    },
    sanitizeKeyPressOperators(event: KeyboardEvent) {
      const key = event.key.toString().toLowerCase()
      const keys = ['backspace', 'delete', 'tab', 'arrowleft', 'arrowright', '-', '+', '.', 'e']
      for (let i = 0; i < 10; i++) keys.push(i.toString())
      if (!keys.includes(key)) event.preventDefault()
      if (this.allowOperators) return
      if (key === 'e' || key === '+' || key === '-' || key === '.') event.preventDefault()
    },
    updateNumber(event: Event) {
      const input = (event as InputEvent).target as HTMLInputElement
      const value: string = input.value || ''
      if (value !== '') {
        const valueNum: number = Number(value)
        if (valueNum < this.min) {
          input.value = this.min?.toString()
          return
        } else if (valueNum > this.max) {
          input.value = this.max?.toString()
          return
        }
        if (valueNum < this.min) {
          ;((event as InputEvent).target as HTMLInputElement).value = this.min?.toString()
        } else if (valueNum > this.max) {
          ;((event as InputEvent).target as HTMLInputElement).value = this.max?.toString()
        }
        this.$emit('update:number', valueNum) // for v-model:text="boundValue" and @update:text="boundValue = $event"
      }
    }
  },
  computed: {
    numberPropVal(): number {
      if (this.number == undefined || isNaN(Number(this.number))) return 1
      return Number(this.number)
    },
    inputValueDefault(): string {
      if (this.numberPropVal < this.min) return this.min.toString()
      if (this.numberPropVal > this.max) return this.max.toString()
      return this.numberPropVal.toString()
    }
  },
  watch: {
    inputValueDefault: {
      immediate: true,
      handler(value: number) {
        if (value != undefined) this.$emit('update:number', Number(value))
      }
    }
  }
})
</script>
