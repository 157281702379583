<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :buttons="[
      {
        label: 'Cancel',
        onClick: () => {
          confirm(false)
        }
      },
      {
        label: 'Ok',
        onClick: () => {
          confirm(true)
        }
      }
    ]"
  >
    <p
      data-testid="confirm-count-message"
      class="px-2 sm:px-0 whitespace-pre-line text-sm text-gray-500 leading-6"
    >
      {{ dialogMessage }}
    </p>

    <div class="pt-4">
      <number-input
        :label="'Count To Progress: '"
        :number="maxCount"
        @update:number="count = $event"
        :editable="true"
        :min="1"
        :max="maxCount"
        :placeholder="maxCount"
        :show-of-max="true"
      />
    </div>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { confirmNumberDialogClose } from '@/composables'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import NumberInput from '@/components/inputComponents/NumberInput.vue'

export default defineComponent({
  name: 'ConfirmNumberDialogContent',
  emits: ['update:dialogOpen'],
  components: { NumberInput, DialogContent },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: true
    },
    confirmDialogProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    cancel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogTitle() {
      return this.message?.label || ''
    },
    dialogMessage() {
      return this.message?.message || ''
    },
    maxCount() {
      return this.message?.maxCount
    }
  },
  data() {
    return {
      count: undefined as number | undefined,
      message: this.confirmDialogProp as {
        label: string
        message: string
        maxCount?: number
      }
    }
  },
  methods: {
    confirm(confirmed: boolean) {
      const returnCount: number | undefined = confirmed ? this.count : undefined
      confirmNumberDialogClose(confirmed, returnCount)
      this.$emit('update:dialogOpen', false)
    }
  },
  watch: {
    cancel: {
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.confirm(false)
        }
      }
    }
  }
})
</script>
