<template>
  <div class="inline-block bg-white rounded-xl w-fit">
    <div
      class="px-1.5 sm:px-2 py-0 sm:py-0.5 border-2 font-semibold text-[10px] sm:text-xs font rounded-xl w-fit gap-0.5 sm:gap-1 flex items-center"
      :class="[
        $props.class || '',
        { 'px-1': propObject.shrink },
        {
          ' bg-red-400/40 border-red-600/40':
            compareStatus(LocalOrderItemStatusEnum.ReviewBlocked) ||
            compareStatus(OrderStatusEnum.InitError) ||
            compareStatus(LocalOrderItemStatusEnum.SkuResolutionFailed)
        },
        {
          ' bg-orange-400/30 border-orange-600/30':
            compareStatus(OrderStatusEnum.UserReview) ||
            compareStatus(LocalOrderItemStatusEnum.UserReview)
        },
        {
          ' bg-amber-400/25 border-amber-600/30': compareStatus(LocalOrderItemStatusEnum.StockCheck)
        },
        {
          ' bg-yellow-200/40 border-yellow-400/40': compareStatus(
            LocalOrderItemStatusEnum.AwaitingStock
          )
        },
        {
          ' bg-indigo-400/30 border-indigo-600/30':
            compareStatus(LocalOrderItemStatusEnum.AwaitingManufacturing) ||
            compareStatus(OrderStatusEnum.Provisioning)
        },
        {
          ' bg-blue-400/30 border-blue-600/30': compareStatus(
            LocalOrderItemStatusEnum.StagedForNesting
          )
        },
        {
          ' bg-cyan-300/30 border-cyan-600/30': compareStatus(
            LocalOrderItemStatusEnum.AwaitingPostProcessing
          )
        },
        {
          ' bg-teal-400/30 border-teal-500/40': compareStatus(
            LocalOrderItemStatusEnum.ReadyForDispatch
          )
        },
        {
          ' bg-green-400/30 border-green-600/40': compareStatus(OrderStatusEnum.ReadyForDispatch)
        },
        {
          ' bg-lime-500/30 border-lime-700/30': compareStatus(OrderStatusEnum.Complete)
        },
        {
          ' bg-gray-300/30 border-gray-500/30':
            compareStatus(LocalOrderItemStatusEnum.Cancelled) ||
            compareStatus(OrderStatusEnum.Cancelled)
        }
      ]"
    >
      <count-pip-component v-if="propObject?.count" :count="propObject?.count" />
      <div class="flex">
        <!--  ERROR -->
        <exclamation-triangle-icon
          v-if="errorStatus"
          class="w-4 h-4 inline-block ml-[-0.25rem]"
        ></exclamation-triangle-icon>
        <!--  USER INPUT REQUIRED -->
        <wrench-icon
          v-if="requiresInputStatus"
          class="w-4 h-4 inline-block ml-[-0.1rem]"
        ></wrench-icon>
        <!--  WAITING FOR UPDATE -->
        <clock-icon
          v-if="waitingForUpdateStatus"
          class="w-4 h-4 inline-block ml-[-0.25rem]"
        ></clock-icon>
        <!--  COMPLETE -->
        <check-icon v-if="completedStatus" class="w-4 h-4 inline-block ml-[-0.25rem]"></check-icon>
        <!--  CANCELED -->
        <x-mark-icon v-if="canceledStatus" class="w-4 h-4 inline-block ml-[-0.25rem]"></x-mark-icon>
        <!--  IN PROGRESS -->
        <cog8-tooth-icon
          v-if="
            !errorStatus &&
            !requiresInputStatus &&
            !waitingForUpdateStatus &&
            !completedStatus &&
            !canceledStatus
          "
          class="w-4 h-4 inline-block ml-[-0.25rem]"
        ></cog8-tooth-icon>
      </div>

      <!-- STATUS LABEL TEXT -->
      <div
        class="pt-[1px] sm:pt-0"
        :class="
          propObject.shrink
            ? 'block sm:flex max-w-[max(13vw,_4.1rem)] sm:max-w-none super-small-text'
            : 'flex'
        "
        data-testid="status-label-text-test-id"
      >
        {{ propObject.label.replace(/_/g, ' ') }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { OrderStatusEnum } from '@/api'
import { LocalOrderItemStatusEnum } from '@/services/DataServices'
import {
  Cog8ToothIcon,
  WrenchIcon,
  ExclamationTriangleIcon,
  ClockIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline'
import CountPipComponent from '@/components/CountPipComponent.vue'

export default defineComponent({
  name: 'StatusLabelComponent',
  computed: {
    OrderStatusEnum() {
      return OrderStatusEnum
    },
    LocalOrderItemStatusEnum() {
      return LocalOrderItemStatusEnum
    },
    errorStatus() {
      return (
        this.compareStatus(LocalOrderItemStatusEnum.ReviewBlocked) ||
        this.compareStatus(OrderStatusEnum.InitError) ||
        this.compareStatus(LocalOrderItemStatusEnum.SkuResolutionFailed)
      )
    },
    requiresInputStatus() {
      return (
        this.compareStatus(OrderStatusEnum.UserReview) ||
        this.compareStatus(LocalOrderItemStatusEnum.UserReview) ||
        this.compareStatus(LocalOrderItemStatusEnum.StockCheck)
      )
    },
    waitingForUpdateStatus() {
      return this.compareStatus(LocalOrderItemStatusEnum.AwaitingStock)
    },
    completedStatus() {
      return (
        this.compareStatus(LocalOrderItemStatusEnum.Complete) ||
        this.compareStatus(OrderStatusEnum.Complete)
      )
    },
    canceledStatus() {
      return (
        this.compareStatus(LocalOrderItemStatusEnum.Cancelled) ||
        this.compareStatus(OrderStatusEnum.Cancelled)
      )
    }
  },
  props: {
    propObject: {
      type: Object as PropType<{ label: string; shrink?: boolean; count?: number }>,
      required: true
    },
    class: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      icon: null as null | object
    }
  },
  components: {
    CountPipComponent,
    Cog8ToothIcon,
    ClockIcon,
    WrenchIcon,
    ExclamationTriangleIcon,
    CheckIcon,
    XMarkIcon
  },
  methods: {
    compareStatus(enumValue: string) {
      return (
        this.propObject.label.replace(/[_-]/g, ' ').toLowerCase() ===
        enumValue.replace(/[_-]/g, ' ').toLowerCase()
      )
    }
  }
})
</script>

<style scoped>
.super-small-text {
}

@media (max-width: 640px) {
  .super-small-text {
    font-size: 8px;
    font-weight: 500;
    max-width: 2.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    padding-bottom: 1px;
  }
}
</style>
