import { createRouter, createWebHistory } from 'vue-router'
import OrdersView from '@/views/OrdersView.vue'
import LoginView from '@/views/LoginView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import ItemsView from '@/views/ItemsView.vue'
import SKUView from '@/views/SKUView.vue'
import csawfApi from '@/csawf-api'
import FaultsView from '@/views/FaultsView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrdersView
    },
    {
      path: '/items',
      name: 'items',
      component: ItemsView
    },
    {
      path: '/skus',
      name: 'skus',
      component: SKUView
    },
    {
      path: '/faults',
      name: 'faults',
      component: FaultsView
    },
    { path: '/:pathMatch(.*)*', component: NotFoundView }
  ]
})

router.beforeEach((to, from, next) => {
  csawfApi.isLoggedIn().then((loggedIn: boolean) => {
    if (to.path.includes('sentry-debug-frontend')) throw new Error('Sentry Debug Error')
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    csawfApi.updateUser(loggedIn)
    if (authRequired && !loggedIn) {
      next({
        path: 'login',
        replace: true,
        query: { redirect: to.fullPath }
      })
    } else if (to.path.includes('logout')) {
      csawfApi.logout()
    } else if (to.path === '/' || to.path === '') {
      router.push({ path: '/orders' }).then()
    } else {
      next()
    }
  })
})

export default router
