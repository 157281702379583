<template>
  <div class="sm:pt-4 lg:pb-1 flow-root h-full">
    <div class="h-full">
      <div
        class="h-auto w-auto sm:w-full inline-block sm:flex sm:flex-col sm:max-h-full min-w-full align-middle sm:pt-4 lg:p-5 bg-white sm:rounded-md lg:rounded-3xl shadow-md"
      >
        <loading-wrapper-component :loading="loading">
          <!-- DISPLAY ROW GROUPS CHECKBOX -->
          <div
            id="table-header"
            v-if="filters.length > 0 || showTextInputFilter || enableShowGroupsCheckbox"
            class="px-2 pt-0 sm:pr-6 sm:pb-4 lg:pr-2 grid sm:flex sm:flex-wrap w-full sm:gap-4"
            :class="[
              { 'sm:justify-between': enableShowGroupsCheckbox },
              { 'sm:justify-end': !enableShowGroupsCheckbox }
            ]"
          >
            <div
              class="inline-flex justify-center sm:justify-end items-center p-4 sm:p-0 sm:pl-2 mt-1 sm:mt-0 ml-1 sm:ml-0 sm:mr-[-0.05rem] text-center sm:text-center capitalize whitespace-break-spaces"
              v-if="enableShowGroupsCheckbox"
            >
              <label for="row-group-checkbox" class="hidden">Group Rows By Active Column</label>
              <input
                title="row-group-checkbox"
                data-testid="row-group-checkbox"
                type="checkbox"
                class="p-[clamp(0.6rem,_3vw,_1rem)] sm:p-0 m-0 sm:m-2 sm:m0 cursor-pointer left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                :checked="displayRowGroups"
                @change="displayGroups($event)"
              />
              <span
                class="select-none cursor-default inline-flex items-center pr-4 pl-8 sm:pl-4 bg-transparent capitalize p-2 text-sm font-semibold text-gray-700"
                >Group Rows By
                {{ columnToGroupExclusively ? columnToGroupExclusively : 'Active Column' }}</span
              >
            </div>

            <table-filter-input
              :filters="filters"
              :show-text-input-filter="showTextInputFilter"
              @update:clear-filters="$emit('update:clearFilters')"
              @update:filter-by-text="$emit('update:filterByText', $event)"
              @update:filter="updateFilter($event.filter, $event.selected)"
            />
          </div>

          <!--  Navigation On Table Top For Mobile  -->
          <table-navigation
            :current-page-number="currentPageNumber"
            @update:currentPageNumber="changePage($event)"
            :total-pages="totalPages"
            :paginate="paginate"
            :class="'pt-0 px-2 pb-6'"
            class="sm:hidden flex"
          />

          <div class="sm:overflow-auto h-auto sm:pb-4">
            <table class="min-w-full divide-y divide-gray-300">
              <slot></slot>
            </table>
          </div>

          <!--  Navigation On Table Base For Desktop  -->
          <table-navigation
            class="sm:flex hidden"
            :current-page-number="currentPageNumber"
            @update:currentPageNumber="changePage($event)"
            :total-pages="totalPages"
            :paginate="paginate"
          />
        </loading-wrapper-component>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { showToolTip } from '@/composables'
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { Filter, mapFilters } from '@/services/DataServices'
import { throwError } from '@/services/ErrorHandler'
import TableFilterInput from '@/components/Table/TableFilterInput.vue'
import TableNavigation from '@/components/Table/TableNavigation.vue'
import LoadingWrapperComponent from '@/components/LoadingWrapperComponent.vue'

export default defineComponent({
  name: 'DTable',
  components: { LoadingWrapperComponent, TableFilterInput, TableNavigation },
  emits: [
    'update:displayRowGroups',
    'update:filters',
    'update:currentPageNumber',
    'update:filterByText',
    'update:clearFilters'
  ],
  props: {
    filtersProp: {
      type: Array as PropType<Filter[]>,
      default: () => []
    },
    enableShowGroupsCheckbox: {
      type: Boolean,
      default: false
    },
    displayRowGroups: {
      type: Boolean,
      default: false
    },
    columnToGroupExclusively: {
      type: String as PropType<string | null>
    },
    showTextInputFilter: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined
    },
    numTableRows: {
      type: Number,
      default: 0
    },
    currentPageNumber: {
      type: Number as PropType<number>,
      default: 0
    },
    totalPages: {
      type: Number as PropType<number>,
      default: 0
    },
    paginate: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pagesArray() {
      let arr = [] as number[]
      if (this.paginate) {
        for (let i = 1; i <= this.totalPages; i++) {
          arr.push(i)
        }
      }
      return arr
    }
  },
  data() {
    return {
      filters: [] as Filter[]
    }
  },
  created() {
    try {
      this.filters = mapFilters(this.filtersProp)
    } catch (e) {
      throwError('Error in DTable filter props.', e)
    }
  },
  methods: {
    changePage(pageNumber: number) {
      this.$emit('update:currentPageNumber', pageNumber)
    },
    FunnelIcon,
    showToolTip,
    updateFilter(filter: Filter, event: string) {
      filter.activeFilterIndex = filter.propertyFilterValues.indexOf(event)
    },
    displayGroups(event?: Event) {
      // Ensure first page is displayed
      this.changePage(1)
      // Update display row groups
      const showGroups: boolean = event
        ? (event.target as HTMLInputElement).checked
        : !this.displayRowGroups
      this.$emit('update:displayRowGroups', showGroups)
    }
  },
  watch: {
    filtersProp: {
      handler: async function (newFilters: Filter[]) {
        if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
          try {
            this.filters = mapFilters(this.filtersProp)
          } catch (e) {
            throwError('Error in DTable filter props.', e)
          }
        }
      },
      deep: true,
      immediate: true
    },
    filters: {
      handler: async function (newFilters: Filter[]) {
        if (
          newFilters &&
          newFilters.length > 0 &&
          JSON.stringify(newFilters) !== JSON.stringify(this.filtersProp)
        ) {
          this.$emit('update:filters', mapFilters(newFilters))
        }
      },
      deep: true,
      immediate: true
    }
  }
})
</script>
