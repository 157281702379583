<template>
  <th
    v-if="!arrayIncludesStringLike(hideCells, cell)"
    scope="col"
    :class="[
      $attrs.class,
      'min-w-[1.25rem] whitespace-nowrap py-2 text-left text-sm font-semibold text-gray-900',
      tableCellClasses,
      { 'hide-if-small-screen': arrayIncludesStringLike(smallScreenHiddenFields, cell) }
    ]"
    :style="'min-width: 1.25rem; z-index: 1; ' + styleOverride || ''"
  >
    <slot></slot>
  </th>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { arrayIncludesStringLike } from '@/services/DataServices'

const props = defineProps({
  styleOverride: {
    default: '',
    type: String
  },
  firstCell: {
    type: Boolean,
    default: false
  },
  hideCells: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  smallScreenHiddenFields: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  cell: {
    type: String,
    default: ''
  }
})

let tableCellClasses = props.firstCell ? 'pl-4 pr-3 sm:px-0 sm:pl-0' : 'px-0 sm:px-2'
</script>
