<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class + ' py-1.5'"
    :tooltip="tooltip"
    data-testid="text-field-input-test-id"
    :user-input-visible="editable"
    :show-divider="showDivider"
    :disabled="disabled"
  >
    <textarea
      v-show="editable"
      :autocomplete="autocomplete"
      :name="name"
      :id="id"
      :required="required"
      ref="textarea"
      :class="[
        {
          'ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600':
            editable
        },
        { 'text-gray-400 bg-gray-100': disabled && editable },
        { 'text-gray-900': !disabled || !editable },
        { 'pointer-events-none': !editable || disabled }
      ]"
      @input="textAreaUpdated"
      :value="text as string"
      rows="1.5"
      cols="0"
      class="pl-1.5 text-area w-full text-center shadow-sm block max-w-2xl rounded-md border-0 placeholder:text-gray-400 sm:text-sm sm:leading-5"
      placeholder=""
      style="min-height: 2rem"
    />
    <div
      v-show="!editable"
      class="flex-1 w-full bg-white text-center text-gray-900 max-h-40 overflow-auto h-fit min-h-8 px-3 sm:text-sm py-1.5"
    >
      <span class="cursor-text whitespace-break-spaces">
        {{ text as string }}
      </span>
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import type { PropType } from 'vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'TextFieldInput',
  components: { InputComponentBase },
  emits: ['update:text'],
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    text: {
      type: String as PropType<string | number | null>,
      value: [
        String as PropType<string | number | null>,
        Number as PropType<string | number | null>,
        null,
        undefined
      ],
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      value: [String, Number, null],
      default: ''
    },
    name: {
      value: String,
      default: 'text-field-input'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  methods: {
    textAreaUpdated($event: Event) {
      this.setFieldHeight()
      if (this.disabled === false && this.editable === true) {
        this.$emit('update:text', ($event.target as HTMLInputElement).value) // for v-model:text="boundValue" and @update:text="boundValue = $event"
      }
    },
    setFieldHeight() {
      const textarea = this.$refs.textarea as HTMLTextAreaElement
      const height: number = parseFloat(textarea.style.height.replace(/px/g, '')) || 0
      const scrollHeight: number = textarea.scrollHeight || 0

      if (scrollHeight > 0 && height <= scrollHeight) {
        textarea.style.height = scrollHeight + 'px'
      }
    }
  },
  mounted() {
    nextTick(() => {
      this.setFieldHeight()
    })
  }
})
</script>

<style scoped>
.text-area {
  max-height: clamp(2.5rem, calc(90vh - 20rem), 7.5rem);
}
</style>
