<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :buttons="[
      {
        label: 'Cancel',
        onClick: () => {
          confirm(false)
        }
      },
      {
        label: 'Ok',
        onClick: () => {
          confirm(true)
        }
      }
    ]"
  >
    <p class="px-2 sm:px-0 whitespace-pre-line text-sm text-gray-500 leading-6">
      {{ dialogMessage }}
    </p>
    <div v-if="message?.alert" class="pt-5" data-testid="confirm-alert-message">
      <alert-message-component
        :message="message.alert.message"
        :message-type="message.alert.messageType"
      />
    </div>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { confirmDialogClose } from '@/composables'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import AlertMessageComponent, { type MessageType } from '@/components/AlertMessageComponent.vue'

export default defineComponent({
  name: 'ConfirmDialogContent',
  emits: ['update:dialogOpen'],
  components: { DialogContent, AlertMessageComponent },
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    confirmDialogProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    cancel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogTitle() {
      return this.message.label || ''
    },
    dialogMessage() {
      return this.message.message || ''
    }
  },
  data() {
    return {
      count: undefined as number | undefined,
      message: this.confirmDialogProp as {
        label: string
        message: string
        alert?: { message: string; messageType?: MessageType }
      }
    }
  },
  methods: {
    confirm(confirmed: boolean) {
      confirmDialogClose(confirmed)
      this.$emit('update:dialogOpen', false)
    }
  },
  watch: {
    cancel: {
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.confirm(false)
        }
      }
    }
  }
})
</script>
