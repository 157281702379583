<template>
  <main>
    <div class="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 class="text-6xl font-bold mb-8">404</h1>
      <p class="text-xl mb-8">Page not found</p>
      <button class="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-900" @click="goBack">
        Go Back
      </button>
    </div>
  </main>
</template>

<script lang="ts">
import router from '@/router'
export default {
  name: 'NotFoundView',
  methods: {
    goBack() {
      router.go(-1)
    }
  }
}
</script>

<style>
/* Add custom styles using Tailwind CSS classes */
</style>
