<template>
  <dialog-content :dialog-title="dialogTitle" :buttons="[{ label: 'Ok', onClick: closeDialog }]">
    <p
      id="id-error-dialog-content-message"
      class="px-2 sm:px-0 whitespace-pre-line text-sm text-gray-500"
    >
      {{ error && error.message ? error.message : 'No Error Message Provided.' }}
    </p>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { LocalError } from '@/services/DataServices'
import { showSpinner } from '@/composables'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'

export default defineComponent({
  name: 'ErrorDialogContent',
  components: { DialogContent },
  emits: ['update:dialogOpen'],
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    error: {
      type: Object as PropType<LocalError | null>,
      required: true
    }
  },
  computed: {
    dialogTitle() {
      return this.error && this.error.name ? this.error.name : 'No Error Title Provided'
    }
  },
  methods: {
    closeDialog() {
      // Transmits dialogOpen value to v-model:dialog-open="dialogOpen"
      this.$emit('update:dialogOpen', false)
      if (this.error && this.error.then) {
        this.error.then()
      }
    }
  },
  watch: {
    dialogOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          showSpinner(false)
        }
      }
    }
  }
})
</script>
