<template>
  <Transition>
    <img
      data-testid="loading-wrapper-spinner"
      class="loading-spinner"
      src="/spinner.svg"
      alt="loading"
      v-if="loading"
    />
  </Transition>
  <div class="loading-default" :class="{ 'obscure-loading': loading }">
    <slot>
      <!-- Affected Content -->
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoadingWrapperComponent',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped>
.loading-spinner {
  position: absolute;
  width: 40%;
  max-height: 80%;
  pointer-events: none;
  user-select: none;
  max-width: 10rem;
  opacity: 0.7;
  top: 50%;
  left: calc(50% - calc(min(10rem, 40%) / 2));
  z-index: 10;
}

/* Transition animation classes */
.v-enter-active,
.v-leave-active {
  transition: all 0.35s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loading-default {
  display: contents;
}

.loading-default > :slotted(*) {
  transition: all 0.35s ease-out;
}

.obscure-loading {
  display: contents;
}

.obscure-loading > :slotted(*) {
  user-select: none;
  pointer-events: none;
  opacity: 0.7;
  filter: blur(1px);
}
</style>
