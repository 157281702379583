<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="$attrs.class"
    :tooltip="tooltip"
    data-testid="button-input-test-id"
    :user-input-visible="true"
    :show-divider="showDivider"
    :disabled="disabled"
    :full-width-on-blank-label="false"
  >
    <button
      :name="name"
      data-testid="button-input-test-id"
      type="button"
      class="select-none w-full global-button sm:py-1.5 inline-flex sm:grid sm:col-span-2 justify-center rounded-md bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      @click="action()"
    >
      {{ buttonText }}
    </button>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

export default defineComponent({
  name: 'ButtonInput',
  components: { InputComponentBase },
  props: {
    buttonText: {
      type: String as PropType<string | number | null>,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    id: {
      value: [String, Number, null],
      default: ''
    },
    name: {
      value: String,
      default: 'button-input'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    action: {
      type: Function,
      required: true
    }
  }
})
</script>
