<template>
  <!-- Content With Expand Button And Border -->
  <div v-if="!hideBorder" :class="$props.class" class="overflow-hidden">
    <div
      data-testid="expandable-content-header"
      class="overflow-auto orderItemHeader flex items-start hover:bg-slate-600/[0.1]"
      :class="[{ 'p-1 sm:p-1 sm:pr-2': minimalPadding }, { 'p-4 sm:p-2': !minimalPadding }]"
      @click="showItem()"
    >
      <table-icon-component
        class="w-auto justify-start inline-flex pr-2"
        v-if="labelIconComponent && labelIconComponent?.placeBefore"
        :prop-object="labelIconComponent"
      />
      <div class="overflow-hidden sm:ml-3 flex h-6 items-center w-full sm:pr-4">
        <div class="overflow-hidden sm:pr-2 flex h-6 items-center w-full justify-center">
          <label
            for="about"
            class="text-left max-w-[max(7rem,_calc(100dvw_-_8rem))] sm:max-w-[22rem] sm:min-w-[10rem] overflow-hidden truncate block text-sm font-medium leading-6 text-gray-900"
            :class="$props.labelClass || ''"
          >
            {{ label }}
            <table-icon-component
              v-if="labelIconComponent && !labelIconComponent?.placeBefore"
              class="contents"
              :prop-object="labelIconComponent"
            />
          </label>
        </div>
        <ChevronDownIcon
          class="itemChevron h-6 w-6 accent-gray-500"
          aria-hidden="true"
          :class="{ itemShownChevron: overRideShown || isShown }"
        />
      </div>
    </div>
    <div
      data-testid="expandable-content-content"
      class="content contentCollapsed"
      :class="{ contentExpanded: overRideShown || isShown }"
      ref="ExpandableContent"
    >
      <div
        :style="maxHeightString + innerStyleOverride"
        ref="InnerContent"
        @scroll="updateScrollEvent"
        @wheel="updateWheelEvent"
        @resize="updateHeight"
      >
        <transition
          enter-from-class="contentCollapsed content"
          enter-to-class="contentExpanded content"
          leave-from-class="contentExpanded content"
          leave-to-class="contentCollapsed content"
        >
          <div
            v-if="overRideShown || isShown"
            :class="[
              { 'pb-1 px-0 sm:pr-4 sm:pb-2': minimalPadding },
              { 'pb-4 px-1 sm:px-4': !minimalPadding }
            ]"
          >
            <slot>
              <!-- <expandable-content> Content Will Be Slotted In Here </expandable-content>-->
            </slot>
          </div>
        </transition>
      </div>
    </div>
  </div>

  <!-- Expandable Content With No Expand Button Or Border -->
  <div v-if="hideBorder" :class="$props.class" class="overflow-hidden" @scroll="updateScrollEvent">
    <div
      class="content contentCollapsed"
      :class="[{ contentExpanded: overRideShown || isShown }]"
      ref="ExpandableContent"
    >
      <div
        tabindex="-1"
        id="InnerContent"
        ref="InnerContent"
        :style="maxHeightString + innerStyleOverride"
        @scroll="updateScrollEvent"
        @wheel="updateWheelEvent"
        @resize="updateHeight"
        @mousedown="mouseDown($event)"
        @mouseup="mouseUp($event)"
      >
        <transition
          enter-from-class="contentCollapsed content"
          enter-to-class="contentExpanded content"
          leave-from-class="contentExpanded content"
          leave-to-class="contentCollapsed content"
        >
          <div v-if="overRideShown || isShown">
            <slot>
              <!-- <expandable-content> Content Will Be Slotted In Here </expandable-content>-->
            </slot>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, type PropType } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import TableIconComponent from '@/components/TableIconComponent.vue'
import { ExpandableTab } from '@/services/DataServices'

export default defineComponent({
  name: 'ExpandableContent',
  components: { TableIconComponent, ChevronDownIcon },
  props: {
    class: {
      default: '',
      type: String
    },
    minimalPadding: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    labelClass: {
      default: '',
      type: String
    },
    labelIconComponent: {
      type: Object as PropType<{
        iconComponent?: object | undefined
        before?: string | undefined
        after?: string | undefined
        tooltip?: string | undefined
        class?: string | undefined
        textClass?: string | undefined
        placeBefore?: boolean | undefined
      }>
    },
    hideBorder: {
      default: false,
      type: Boolean
    },
    overRideShown: {
      default: false,
      type: Boolean
    },
    expandedByDefault: {
      default: false,
      type: Boolean
    },
    overrideTransitionTimeInMs: {
      default: 350,
      type: Number
    },
    maxHeight: {
      // px, rem or em recommended. Example, 10rem
      default: undefined,
      type: String
    },
    innerStyleOverride: {
      default: '',
      type: String
    }
  },
  refs: ['ExpandableContent', 'InnerContent'],
  data() {
    return {
      isShown: false as boolean,
      referenceHeight: '0' as string,
      resizeObserver: null as null | ResizeObserver,
      transitionTime: '350ms' as string
    }
  },
  computed: {
    maxHeightString() {
      return this.maxHeight ? 'max-height:' + this.maxHeight + '; overflow:auto;' : ('' as string)
    }
  },
  methods: {
    updateHeight() {
      this.referenceHeight = ((this.$refs.InnerContent as any).scrollHeight as string) + 'px'
      let maxHeightPx = undefined as undefined | number
      if (this.maxHeight) {
        if (this.maxHeight.includes('px')) {
          maxHeightPx = parseInt(this.maxHeight.substring(0, this.maxHeight.length - 2))
        } else if (this.maxHeight.includes('rem')) {
          maxHeightPx =
            parseInt(this.maxHeight.substring(0, this.maxHeight.length - 2)) *
            parseFloat(getComputedStyle(document.documentElement).fontSize)
        } else if (this.maxHeight.includes('em')) {
          maxHeightPx =
            parseInt(this.maxHeight.substring(0, this.maxHeight.length - 2)) *
            parseFloat(getComputedStyle(this.$el || document.documentElement).fontSize)
        }
        if (maxHeightPx) {
          this.referenceHeight = maxHeightPx.toString() + 'px'
        }
      }
      this.$emit(
        'update:referenceHeight',
        maxHeightPx || (this.$refs.InnerContent as any).scrollHeight
      )
    },
    showItem() {
      this.isShown = !this.isShown
    },
    updateScrollEvent(event: any) {
      if (this.overRideShown || this.isShown) {
        this.$emit('scroll', event)
      }
    },
    updateWheelEvent(event: any) {
      if (this.overRideShown || this.isShown) {
        this.$emit('wheel', event)
      }
    },
    mouseDown(event: any) {
      const el = event.target || event.srcElement
      // Prevents inner elements triggering mouse down
      if ((this.overRideShown || this.isShown) && el?.id && (el.id as string) === 'InnerContent') {
        this.$emit('update:mouseInteraction', 'down')
      }
    },
    mouseUp(event: any) {
      const el = event.target || event.srcElement
      // Prevents inner elements triggering mouse up
      if ((this.overRideShown || this.isShown) && el?.id && (el.id as string) === 'InnerContent') {
        this.$emit('update:mouseInteraction', 'up')
      }
    }
  },
  emits: ['update:mouseInteraction', 'update:referenceHeight', 'scroll', 'wheel', 'expanded'],
  mounted() {
    // Capture content size to expand to
    nextTick(() => {
      this.updateHeight()
    })
    // Ensure screen or content resizing updates expanded size
    this.resizeObserver = new ResizeObserver(this.updateHeight)
    this.resizeObserver.observe(this.$refs.InnerContent as HTMLDivElement)
    this.transitionTime = this.overrideTransitionTimeInMs.toString() + 'ms'
    this.isShown = this.expandedByDefault || this.overRideShown
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.InnerContent as HTMLDivElement)
    }
  },
  watch: {
    isShown: {
      handler: function (newVal: boolean) {
        this.$emit('expanded', new ExpandableTab(newVal, this.showItem.bind(this)))
      },
      immediate: true
    }
  }
})
</script>

<style scoped>
.content {
  --transition-time: v-bind(transitionTime);
  transition-duration: var(--transition-time);
  transition-timing-function: ease;
  transition-property: max-height;
}

.contentCollapsed {
  max-height: 0;
}

.contentExpanded {
  --inner-content-height: v-bind(referenceHeight);
  max-height: var(--inner-content-height);
}

.itemChevron {
  z-index: -1;
  position: relative;
  top: 0;
  transition: transform 0.35s ease;
}

.itemShownChevron {
  transform: rotate(180deg);
}
</style>
