<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="saveNewSKU"
    :buttons="[
      { label: editable ? 'Cancel' : 'Close', onClick: closeDialog },
      {
        label: 'Save',
        type: 'submit',
        condition: editable,
        loading: loading,
        id: 'sku-save-button'
      }
    ]"
  >
    <div class="overflow-y-visible overflow-x-hidden break-words py-1 max-h-fit">
      <!--  Label: -->
      <text-input
        required
        :label="'Label'"
        id="sku-label-input"
        v-model:text="dialogSku.label"
        :editable="editable"
      />
      <!--  Description: -->
      <text-input
        :label="'Description'"
        v-model:text="dialogSku.description"
        :editable="editable"
      />
      <!--  Date Created: -->
      <text-input
        v-if="!editable"
        :label="'Date Created'"
        :text="processHumanReadableDate(dialogSku.created, true)"
      />
      <!--  Last Updated: -->
      <text-input
        v-if="!editable"
        :label="'Last Updated'"
        :text="processHumanReadableDate(dialogSku.updated)"
      />
      <!--  Type: -->
      <list-input
        id="sku-type-drop-down"
        v-if="dialogSku.sku_type || editable"
        :editable="editable"
        :label="'SKU Type'"
        :options="getEnumAsArray(SkuSkuTypeEnum)"
        v-model:selected="dialogSku.sku_type"
        :enum-key-to-label-object-array="skuTypes()"
      />
      <!--  Material: -->
      <list-input
        id="sku-material-drop-down"
        :class="'pt-0'"
        v-if="isNotBundleOrResale()"
        :editable="editable"
        :label="'Material'"
        :options="getMaterialsForDropDown"
        :selected="dialogSku.material || null"
        @update:selected="dialogSku.material = $event"
        :object-label-key-name="'label'"
      />
      <!--  Design Type: -->
      <list-input
        v-if="isNotBundleOrResale()"
        :editable="editable"
        :label="'Design Type'"
        :options="getEnumAsArray(SkuDesignTypeEnum)"
        v-model:selected="dialogSku.design_type"
        :enum-key-to-label-object-array="designTypes()"
      />

      <!-- New Child SKUs: -->
      <expandable-content
        v-if="editable && dialogSku.sku_type && dialogSku.sku_type.toUpperCase() === 'BUNDLE'"
        :expanded-by-default="true"
        class="w-50 rounded-lg sm:rounded-2xl gap-1 overflow-hidden m-1 shadow-[1px_1px_4px_0_rgba(0,0,0,0.2)]"
        :label="'Child Skus'"
      >
        <!--  Child SKU (NEW SKU ONLY): -->
        <div class="w-full flex justify-center py-4">
          <button
            type="button"
            v-tooltip="'Add Child SKU'"
            class="cursor-pointer sm:col-span-2 p-2 sm:p-1 flex w-12 h-12 sm:h-8 sm:w-8 text-gray-600 hover:text-gray-950 hover:bg-slate-600/[0.1] rounded-3xl shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]"
            @click="addChildSKU"
          >
            <document-plus-icon
              style="padding-left: 1px; padding-bottom: 1px"
              class="h-full w-full"
              aria-hidden="true"
            />
          </button>
        </div>

        <div v-if="dialogSku.child_skus && dialogSku.child_skus.length > 0">
          <div
            v-for="(childSku, index) in dialogSku.child_skus
              .slice()
              .reverse() as SkuChildSkusInner[]"
            :key="dialogSku.child_skus.length - index - 1"
          >
            <div class="flex gap-1 sm:gap-4">
              <button
                type="button"
                v-tooltip="'Delete Child SKU'"
                class="min-w-[2rem] min-h-[2rem] cursor-pointer mt-6 sm:mt-0.5 self-center p-0 sm:p-1 flex w-9 h-8 text-gray-600 rounded-3xl sm:w-8 hover:text-gray-950 hover:bg-slate-600/[0.1]"
                @click="removeChildSKU(dialogSku.child_skus.length - index - 1)"
              >
                <trash-icon
                  style="padding-left: 1px; padding-bottom: 1px"
                  class="h-full w-full"
                  aria-hidden="true"
                />
              </button>
              <list-input
                :update-key="updateKey"
                class="w-full"
                :editable="editable"
                :label="'Child SKU ' + (dialogSku.child_skus.length - index)"
                :options="getSkusForDropDown"
                :selected="initialSku"
                @update:selected="
                  dialogSku.child_skus[dialogSku.child_skus.length - index - 1].child_sku =
                    ($event as LocalSku).id || -1
                "
                :object-label-key-name="'label'"
                :custom-label-get-function="customLabelGetFunction"
                :max-height="'17rem'"
              />
              <span
                class="text-gray-900 flex self-end sm:self-center mb-2 sm:mb-0 justify-center w-4"
                >×</span
              >
              <div class="py-0.5 sm:py-0 flex items-end mb-[-0.1rem] sm:mb-0">
                <number-input
                  :min="1"
                  v-model:number="
                    dialogSku.child_skus[dialogSku.child_skus.length - index - 1].count
                  "
                  :editable="editable"
                  :input-style-override="'width: clamp(3rem, 15dvw, 7rem);'"
                />
              </div>
            </div>
          </div>
        </div>
      </expandable-content>

      <!-- Existing Child SKUs: -->
      <div v-if="selectedChildSkus.length > 0 && !editable">
        <expandable-content
          v-for="(childSku, index) in selectedChildSkus as LocalSku[]"
          :key="childSku.id"
          class="sm:min-w-[20rem] w-50 rounded-lg sm:rounded-2xl gap-1 overflow-hidden my-2 sm:m-1 shadow-[1px_1px_4px_0_rgba(0,0,0,0.2)]"
          :label="
            'Child Sku ' + childSku.id?.toString() + (childSku.label ? ' : ' + childSku.label : '')
          "
        >
          <!--  Label: -->
          <text-input :label="'Label'" :text="childSku.label" />
          <!--  Label: -->
          <text-input
            v-if="childSku.description"
            :label="'Description'"
            :text="childSku.description"
          />
          <!--  Count: -->
          <text-input :label="'Count'" :text="dialogSku.child_skus[index].count || 1" />
          <!--  Date Created: -->
          <text-input :label="'Date Created'" :text="processHumanReadableDate(childSku.created)" />
          <!--  Type: -->
          <text-input :label="'SKU Type'" :text="childSku.sku_type" />
          <!--  Material: -->
          <text-input
            v-if="childSku?.material?.label"
            :label="'Material'"
            :text="childSku.material.label"
          />
          <!--  Design Type: -->
          <text-input
            v-if="childSku.design_type"
            :label="'Design Type'"
            :text="childSku.design_type"
          />
          <!--  Child SKUs: -->
          <text-input
            v-if="childSku.child_skus && childSku.child_skus.length > 0"
            :label="'Child SKUs'"
            :text="childSkusString(childSku?.child_skus)"
          />
        </expandable-content>
      </div>
    </div>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Sku, SkuChildSkusInner } from '@/api'
import { throwError } from '@/services/ErrorHandler'
import {
  createUpdatedSkusListener,
  emitAddNewSkuToItem,
  emitSkusUpdated,
  newErrorDialog,
  showSpinner,
  showToolTip
} from '@/composables'
import {
  type SkuDialogObject,
  skuTypes,
  LocalSku,
  api,
  designTypes,
  processHumanReadableDate,
  getEnumAsArray,
  getSkusForDropDown,
  getInitialSku,
  getMaterialsForDropDown
} from '@/services/DataServices'
import { SkuDesignTypeEnum, SkuSkuTypeEnum } from '@/api'
import TextInput from '@/components/inputComponents/TextInput.vue'
import ListInput from '@/components/inputComponents/ListInput.vue'
import ExpandableContent from '@/components/ExpandableContent.vue'
import NumberInput from '@/components/inputComponents/NumberInput.vue'
import { DocumentPlusIcon, TrashIcon } from '@heroicons/vue/24/outline'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'

export default defineComponent({
  name: 'ViewSkuDialogContent',
  emits: ['update:dialogOpen'],
  computed: {
    SkuSkuTypeEnum() {
      return SkuSkuTypeEnum
    },
    SkuDesignTypeEnum() {
      return SkuDesignTypeEnum
    },
    LocalSku() {
      return LocalSku
    },
    dialogTitle() {
      return this.editable ? 'New SKU' : 'SKU ' + this.dialogSku.id + ' Details'
    }
  },
  components: {
    TextInput,
    ListInput,
    ExpandableContent,
    NumberInput,
    DocumentPlusIcon,
    TrashIcon,
    DialogContent
  },
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    skuProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateKey: 0 as number,
      initialSku: {} as LocalSku,
      dialogSku: {} as LocalSku,
      relevantSkus: [] as LocalSku[], // Used for displaying read only grand-child sku information
      selectedChildSkus: [] as LocalSku[],
      customLabelGetFunction: (value: Sku) => {
        return value.description ? value.label + '  -  ' + value.description : value.label
      },
      loading: false as boolean,
      itemIdAwaitingNewSKU: undefined as number | undefined
    }
  },
  methods: {
    getMaterialsForDropDown,
    getSkusForDropDown,
    skuTypes() {
      return skuTypes
    },
    getEnumAsArray,
    designTypes() {
      return designTypes
    },
    processHumanReadableDate,
    isNotBundleOrResale(): boolean {
      return (
        (this.dialogSku && this.dialogSku.material && !this.editable) ||
        (this.editable &&
          this.dialogSku.sku_type !== undefined &&
          !(
            this.dialogSku.sku_type === SkuSkuTypeEnum.Bundle ||
            this.dialogSku.sku_type === SkuSkuTypeEnum.Resale
          ))
      )
    },
    removeChildSKU(index: number) {
      showToolTip(false)
      this.dialogSku.child_skus?.splice(index, 1)
    },
    addChildSKU() {
      if (!this.dialogSku.child_skus) this.dialogSku.child_skus = []
      this.dialogSku.child_skus.push({ child_sku: this.initialSku.id || -1, count: 1 })
    },
    childSkusString(childSkus: SkuChildSkusInner[]): string {
      if (childSkus && childSkus.length > 0) {
        if (childSkus.length === 1) {
          return (
            (this.relevantSkus.find((sku) => sku.id === childSkus[0].child_sku)?.label || '') +
            ((childSkus[0].count || 1) > 1 ? ' [×' + childSkus[0].count + ']' : '')
          )
        } else {
          const childSkuStringArray = childSkus.map((child: SkuChildSkusInner) => {
            return (
              (this.relevantSkus.find((sku) => sku.id === child.child_sku)?.label || '') +
              (child.count && child.count > 1 ? ' [×' + child.count + ']' : '')
            )
          })
          return childSkuStringArray.join(', ')
        }
      }
      return 'None'
    },
    async saveNewSKU() {
      if (this.dialogSku.sku_type === SkuSkuTypeEnum.Bundle) {
        if (!this.dialogSku.child_skus || this.dialogSku.child_skus?.length === 0) {
          newErrorDialog('New SKU Error', 'A SKU Bundle must have at least one child SKU.')
          return
        }
      }

      showSpinner(true)
      if (await this.dialogSku.labelAlreadyExists()) {
        newErrorDialog('New SKU Error', 'A SKU with this label already exists.')
        showSpinner(false)
        return
      }
      const newSKU = await this.dialogSku.create()
      showSpinner(false)
      if (!newSKU) return
      if (this.itemIdAwaitingNewSKU) emitAddNewSkuToItem(this.itemIdAwaitingNewSKU, newSKU)
      this.closeDialog(newSKU)
    },
    closeDialog(newSKU?: LocalSku) {
      if (newSKU) emitSkusUpdated()
      // Transmits dialogOpen value to <view-order-dialog-content v-model:dialog-open="dialogOpen" />
      this.$emit('update:dialogOpen', false)
    },
    async initializeSkus() {
      this.loading = true
      this.selectedChildSkus = await this.getChildSkus()
      this.initialSku = await getInitialSku()
      this.relevantSkus = await this.getRelevantSkus()
      this.loading = false
    },
    async getChildSkus(): Promise<LocalSku[]> {
      const uniqueChildSkuIDs: number[] = this.dialogSku.child_skus
        .map((child: SkuChildSkusInner) => child.child_sku || -1)
        .filter((sku, index, self) => self.indexOf(sku) === index && sku > -1)
      const necessarySkus: LocalSku[] = await api.getSkusIn(uniqueChildSkuIDs)
      return this.dialogSku.child_skus.map(
        (child: SkuChildSkusInner) =>
          necessarySkus.find((sku: LocalSku) => sku.id === child.child_sku) as LocalSku
      )
    },
    async getRelevantSkus(): Promise<LocalSku[]> {
      let existingSkus: LocalSku[] = [this.dialogSku, ...this.selectedChildSkus]
      const existingIDs = existingSkus.map((sku: LocalSku) => sku.id)
      const grandChildSkus = this.selectedChildSkus.map((s: LocalSku) => s.child_skus).flat()
      const grandChildSkuIDs = grandChildSkus.map((gcs: SkuChildSkusInner) => gcs.child_sku)
      const reqIDs = grandChildSkuIDs.filter((id: number) => !existingIDs.includes(id))
      const uniqueIDs = reqIDs.filter((id, idx, slf) => slf.indexOf(id) === idx && id > -1)
      const uniqueSkus: LocalSku[] = await api.getSkusIn(uniqueIDs)
      return [...existingSkus, ...uniqueSkus]
    }
  },
  created() {
    try {
      this.dialogSku = new LocalSku((this.skuProp as SkuDialogObject).sku as LocalSku)
      this.itemIdAwaitingNewSKU = (this.skuProp as SkuDialogObject).itemIdAwaitingNewSKU
    } catch (e) {
      throwError('Error in SkuDialog props', e)
      this.closeDialog()
    }
    const updateList = () => this.updateKey++
    createUpdatedSkusListener(updateList.bind(this))
  },
  watch: {
    dialogSku: {
      handler(newValue: LocalSku) {
        if (newValue && newValue.id) {
          this.initializeSkus()
        }
      },
      deep: true,
      immediate: true
    }
  }
})
</script>
